import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Avatar, Dropdown, Menu, Modal } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PoweroffOutlined,
  UserOutlined,
  AlertOutlined,
  WarningOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import KiconexLogo from "../../assets/img/png/logo_comp.png";
import service from "../../services/Service";
import "./MenuTop.scss";
import { useSelector, useDispatch } from 'react-redux';
import { logout, impersonate } from "../../api/user";
import { withLocale } from "../../l10n";
import { saveExpiredInfo, savePendingInfo, saveM2MInfo } from "../../store";
import { REFRESH_INTERVAL_MILISECONDS, REFRESH_INTERVAL_VERSION_MILISECONDS } from "../../utils/constants";
import { getTokenInfo, hasPrivilege } from "../../api/token";

function MenuTop(props) {
  const { menuCollapsed, setMenuCollapsed, homeUrl, lang} = props;
  const store = useSelector(state => state.store);
  const dispatch = useDispatch();
  const itemsActived = [];
  const items = {
    expired_info: {
      key: "1",
      icon: <WarningOutlined />,
      label: <Link to={"/boards/renewals"} style={{ textDecoration: 'none' }} state={{ plan: store.alerts.expired_info.expired_plan }}><span className="nav-text"> {lang.handle('Renovaciones expiradas')} </span></Link>,
      danger: true,
    },
    pending_info: {
      key: "2",
      icon: <WarningOutlined />,
      label: <Link to={"/orders/list"} style={{ textDecoration: 'none' }} state={{ filter: store.alerts.pending_info.visible }}><span className="nav-text"> {lang.handle('Pedidos pendientes')} </span></Link>,
      danger: true,
    },
    alarms_m2m: {
      key: "3",
      icon: <WarningOutlined />,
      label: <Link to={"/simcards/m2m"} style={{ textDecoration: 'none' }} state={{ filter: store.alerts.alarms_m2m.filter }}><span className="nav-text"> {lang.handle('Alarmas M2M')} </span></Link>,
      danger: true,
    }
  }

  const logoutUser = async () => {
    const result = await logout();
    if (result)
      window.location.reload();
  };

  function onClick(e) {
		localStorage.setItem("langCode", e.key);
		window.location.reload();
	};

  const editProfile = () => {
    window.location.href = `/users/edit/${getTokenInfo().uuid}`;
  }

  const backSession = async () => {
    await impersonate(getTokenInfo().oldUser.uuid);
  };

  const functions = {
    expired_info: async function expiredRenewals() {
      if (hasPrivilege('administration_renewal')) {
        await service.get("boards/renewalsExpired").then((res) => {
            if (res.status === 200) {
              res.data.length <= 0 ? dispatch(saveExpiredInfo({expired: {visible: false}})) : dispatch(saveExpiredInfo({expired: {visible: true, expired_plan: res.data.expired_plan}}));
            }
        }).catch((err) => {
            console.log("Error devuelto: " + err);
        });
      }
    },
    pending_info: async function pendingOrders() {
      if (hasPrivilege('orders_view')) {
        await service.get("orders/pending").then((res) => {
            if (res.status === 200) {
              res.data.length <= 0 ? dispatch(savePendingInfo({pending: {visible: false}})) : dispatch(savePendingInfo({pending: {visible: true}}));
            }
        }).catch((err) => {
            console.log("Error devuelto: " + err);
        });
      }
    },
    alarms_m2m: async function alarmsM2MActived() {
      if (hasPrivilege('m2m_view')) {
        const tenant_uuid = getTokenInfo().tenant_uuid;
        let alarmsActived = false;
        const params = {
          tenant_uuid: tenant_uuid,
        }

        const alarms = await service.get('m2m', params).then((res) => {
            if (res.status === 200) {
              for (const element of res.data) {
                if (element.alarms.length >= 1 && element.alarms[0] !== 0) {
                  alarmsActived = true;
                }
              }
              return alarmsActived;
            }
        }).catch((err) => {
            console.log("Error devuelto: " + err);
        });

        const risk = await service.get('m2m/deleted-risk', params).then((res) => {
          if (res.status === 200) {
            return res.data.length > 0;
          }
        }).catch((err) => {
          console.log("Error devuelto: " + err);
        });

        let result = { alarmsActived: { } };
        result.alarmsActived.visible = alarms || risk;
        result.alarmsActived.filter = alarms;

        dispatch(saveM2MInfo(result));
      }
    },
    version: async function version() {
      await service.get("system/version").then((res) => {
          if (res.status === 200) {
            if (!res.data.is_valid) {
              Modal.confirm({
                title: lang.handle("Su sesión va a ser cerrada"),
                content: lang.handle("Posee una versión no compatible"),
                icon: <ExclamationCircleOutlined />,
                cancelButtonProps: { disabled: true, style: { display: 'none' } },
                onOk: () => { logoutUser(); },
              });
            }
          }
      }).catch((err) => {
          console.log("Error devuelto: " + err);
      });
    },
  }

  useEffect(() => {
    functions.version();

    const interval = setInterval(() => {
      functions.expired_info();
      functions.pending_info();
      functions.alarms_m2m();
    }, REFRESH_INTERVAL_MILISECONDS);

    const interval_version = setInterval(() => {
      functions.version();
    }, REFRESH_INTERVAL_VERSION_MILISECONDS);

    return () => { clearInterval(interval); clearInterval(interval_version); };
  // eslint-disable-next-line
  }, [])

  Object.entries(store.alerts).forEach(([key, value]) => {
    if (value.visible) {
      itemsActived.push(items[key]);
    }
  })

  return (
    <div className="menu-top">
      <div className="menu-top__left">
        <Link to={homeUrl}>
          <img
            className="menu-top__left-logo"
            src={KiconexLogo}
            alt="Kiconex"
          />
        </Link>
        <Button type="link" onClick={() => setMenuCollapsed(!menuCollapsed)}>
          {menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </div> 
      <div className="menu-top__right d-flex align-items-center h-100 w-25" style={{justifyContent: 'right'}}>
        { getTokenInfo().oldUser &&
        <div className="impersonateBanner">
          {lang.handle('Personificando al usuario')} {getTokenInfo().username} <button onClick={backSession}>{lang.handle('Volver a la sesión')}</button>
        </div>
        }
        {
        itemsActived.length >= 1 ? (
        <Dropdown overlay={
            <Menu items={itemsActived}/>
        }>
          <AlertOutlined style={{color: 'red', marginRight: '15px'}}/>
        </Dropdown>
        ): null}
        <Dropdown 
          overlay={
            <div className="main">
              {/* <EditOutlined /> */}
              <div className="profile">
                <Avatar size="large" icon={<UserOutlined />} />
                <h5>{getTokenInfo().username}</h5>
                <h5>{getTokenInfo().email}</h5>
                <Button className="gestButton" onClick={editProfile}>{lang.handle("Gestionar perfil")}</Button>
              </div>
              <div className="profile-options">
                <Menu
                  onClick={onClick}
                  items={[
                    {
                      key:'1',
                      label: lang.handle('Seleccionar idioma'),
                      children: [
                        {
                          key:'es',
                          label: lang.handle('Español'),
                        },
                        {
                          key:'en',
                          label: lang.handle('Inglés'),
                        },
                        {
                          key:'fr',
                          label: lang.handle('Francés'),
                        },
                        {
                          key:'de',
                          label: lang.handle('Alemán'),
                        },
                        {
                          key:'pt',
                          label: lang.handle('Portugués'),
                        },
                        {
                          key:'it',
                          label: lang.handle('Italiano'),
                        }
                      ]
                    },
                  ]}>
                </Menu>
                <Button type="link" onClick={logoutUser} className="btnLogout">
                  <PoweroffOutlined/>
                </Button>
              </div>
            </div>
          }
          trigger={['click']}>
          <Avatar icon={<UserOutlined />} />
        </Dropdown>
      </div>
    </div>
  );
}

export default withLocale(MenuTop);
