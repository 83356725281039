import React from 'react';
import { ChromeOutlined  } from '@ant-design/icons';
import { Button } from 'antd';
import { withLocale } from '../../l10n';
function BotonIrKiconex(props){
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    
    return(
        <Button
	        type="primary"
            icon={<ChromeOutlined />}
            disabled={ props.disabled }
	        onClick={()=>openInNewTab(process.env.REACT_APP_KICONEX_URL+'/devices/'+props.uuid+'/view')}
        >
        {props.lang.handle('Ver en kiconex')}
        </Button>
    );
}

export default withLocale(BotonIrKiconex);
