import { useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { hasPrivilege } from "../api/token";


export default function GLPI() {
    const navigate = useNavigate();

    useEffect(() => {
      if (!hasPrivilege('support_view')) {
        navigate("/");
      }

      const URL_GLPI = process.env.REACT_APP_GLPI;
      window.open(URL_GLPI, "_blank");
      navigate("/");

    }, [navigate])
}
