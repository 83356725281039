import React, { Component } from 'react';
import { Select, Checkbox, notification } from "antd";
import "./PrivilegesTable.scss";
import service from "../../services/Service"

import USER_PRIVILEGES from "../../utils/user-privileges";
import { withLocale } from '../../l10n';
const { Option } = Select;


class PrivilegesTable extends Component {
  state = {
    privileges: USER_PRIVILEGES.USER_PRIVILEGES,
    roles: [],
    role: null,
    lang: this.props.lang
  }

  async componentDidMount() {
    this.fetch();
  }

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    await service
      .get("roles")
      .then(res => {
        this.setState({
          roles: res.data
        })
      })
      .catch((err) => {
        console.log("Error devuelto: " + err);
      });

    let datos = [];
    await service.get("privileges/role/" + this.state.role)
      .then(res => {
        const resp = res.data;
        for (let i = 0; i < resp.length; i++) {
          let data = {
            name: resp[i]['name'],
            uuid: resp[i]['uuid'],
            active: resp[i]['value'],
            permission: resp[i]['permission']
          };
          // datos.push(data);
          datos[resp[i]['name']] = (data)
        }
      })
      .catch((err) => {
        console.log("Error devuelto: " + err);
      });

    const groups = Object.keys(this.state.privileges)
    groups.forEach(e => {
      const privs = Object.keys(this.state.privileges[e].privileges)
      privs.forEach(element => {
        this.setState({
          // eslint-disable-next-line
          ...this.state.privileges[e].privileges[element].uuid = datos[element].uuid,
          // eslint-disable-next-line
          ...this.state.privileges[e].privileges[element].active = datos[element].active,
          // eslint-disable-next-line
          ...this.state.privileges[e].privileges[element].permission = datos[element].permission,
        })
      });
    });
  };

  handlePrivileges = (a, b) => (event) => {
    const preState = this.state.privileges[a].privileges[b].active;
    this.setState({
      // eslint-disable-next-line
      ...this.state.privileges[a].privileges[b].active = !preState,
    })
  };

  changeSelect = (value) => {
    this.setState({
      // eslint-disable-next-line
      ...this.state.role = value
    });
    this.fetch();
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { lang } = this.state;
    const roleUuid = this.state.role;
    const privilegeData = this.state.privileges;

    await service
      .post("privileges/role/" + roleUuid, privilegeData)
      .then((res) => {
        if (res.data.ok === false) {
          notification["error"]({
            message: lang.handle(res.data.message),
          });
          return;
        } else {
          notification["success"]({
            message: lang.handle('Privilegios guardados correctamente'),
          });
          this.fetch();
        }
      })
      .catch((err) => {
        console.log("Error devuelto: " + err);
      });

  };

  render() {
    let { privileges, roles, role } = this.state;

    const {lang} = this.state;

    function Save() {
      return (
        <button
          id="button-save"
          className="btn btn-primary d-flex float-right"
          type="submit"
        >
          <div className="btnText">
            <p> {lang.handle('Guardar')}&nbsp;&nbsp; </p>
            <i className="material-icons"> save </i>
          </div>
        </button>
      );
    }

    return (
      <form onSubmit={this.onSubmit}>
        <div className="userForm-selectMargin">
          <span className="form-select1">{lang.handle('Rol')  + ":"}</span>
          <Select
            style={{ width: 200 }}
            placeholder={lang.handle('Selecciona un rol')}
            optionFilterProp="rol"
            onSelect={(value, event) => this.changeSelect(value, event)}
            name="role"
            id="role"
            className="register-form__select"
          >
            {roles.map((option) => (
              <Option type='role_uuid' key={option.uuid} value={option.uuid}>
                {option.name}
              </Option>
            ))}
          </Select>
        </div>
        {role ?
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{lang.handle('Nombre')}</th>
                  <th scope="col">{lang.handle('Privilegios')}</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(privileges).map((key, i) => {
                  return (
                    <tr key={key}>
                      <td><span className='title'>{lang.handle(privileges[key].label)}</span><br></br>{lang.handle(privileges[key].description)} </td>
                      <td className='row-priv'>
                        {Object.keys(privileges[key].privileges).map((key2, j) => {
                          return (
                            <div key={key2} className='celda'>
                              <Checkbox
                                id={key2}
                                name={key2}
                                checked={privileges[key].privileges[key2].active}
                                onChange={this.handlePrivileges(key, key2)}
                              /> {lang.handle(privileges[key].privileges[key2].label)}
                            </div>
                          )
                        })}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="btn-save-privileges">
              <Save />
            </div>
          </div>
          :
          <div>{lang.handle('Debe seleccionar un rol')}</div>
        }
      </form>
    )
  }
}

export default withLocale(PrivilegesTable);
