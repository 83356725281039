import service from '../services/Service';

// Hook para la recogida de datos del backend ante peticiones get
export default async function getPlansFormatted(URL) {
	
	// Array que devuelve los planes y el uuid de estos
	var planName = [];
	var uuid = []

	// Se realiza la peticion al backend
	await service.get('plans')
	.then(res => {
		for (var i = 0; i < res.data.length; i++) {
			planName = planName.concat(res.data[i].name);
			uuid = uuid.concat(res.data[i].uuid);
		}
		return;
	})
	.catch(() => { return })

	return [planName, uuid];
}