import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import VPN from "../components/VPN";


export default function VPNPage() {
  const navigate = useNavigate();
  const vpn_view = hasPrivilege('vpn_view')

  useEffect(() => {
    if (!vpn_view) {
      navigate("/");
    }
  }, [navigate, vpn_view])

  if (vpn_view) {
    return (
      <div>
        <VPN />
      </div>
    )
  }
}
