import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import RoleCard from "../components/RoleCard";

export default function ConfigRolesPage() {
  const navigate = useNavigate();
  const params = useParams();
  const roles_settings = hasPrivilege('roles_settings');

  useEffect(() => {
    if (!roles_settings) {
      navigate("/");
    }
  }, [navigate, roles_settings])

  if (roles_settings) {
    const editable = true;
    const ident = params.uuid;

    return (
      <div>
        <RoleCard editable={editable} ident={ident} />
      </div>
    )
  }
}
