import React, { Component } from 'react';
import { Table } from 'antd';
import { withLocale } from '../../l10n';
import "./DataTable.scss";
import { publish } from '../../utils/events';

class DataTable extends Component {

  state = {
    paginationFrom: 1,
    paginationSize: 10,
    dataCurrentSize: null,
    filter: false,
  };

  handleTableChange = (pagination, filters, sorter, extra) => {
    const arrFilters = Object.values(filters);
    const filter = arrFilters.find(e => e !== null && e.length !== 0);

    const from = (pagination.pageSize * (pagination.current - 1)) + 1;
    const totalShow = Math.min(extra.currentDataSource.length, this.props.dataSource.length);
    const totalPages = Math.floor(totalShow / pagination.pageSize);

    this.setState({
      paginationFrom: from,
      paginationSize: pagination.current > totalPages ? totalShow : pagination.current * pagination.pageSize,
      dataCurrentSize: extra.currentDataSource ? extra.currentDataSource.length : this.props.dataSource.length,
      filter: filter
    })
    publish('datatable-filter', extra.currentDataSource);
  }

  render() {
    const totalShow = Math.min(this.state.paginationSize, this.props.dataSource.length);
    const total = this.state.dataCurrentSize ?? this.props.dataSource.length;

    return (
      <div>
        <Table
          columns={this.props.columns}
          dataSource={this.props.dataSource}
          rowKey={(record) => record[this.props.rowKey]}
          rowSelection={this.props.rowSelection}
          loading={this.props.loading}
          onChange={this.handleTableChange}
          pagination={this.props.pagination}
          scroll={this.props.scroll}

        />
        {this.props.pagination && totalShow > 0 && <div className="counter">
          <span>
            {this.state.paginationFrom + " "}
          </span>
          {"- "}
          <span>
            {totalShow + " "}
          </span>
          <span>
            {this.props.lang.handle("de") + " " + total + " "}
          </span>
          {this.state.filter && <span>
            ({this.props.lang.handle("filtrados de") + " " + this.props.dataSource.length})
          </span>}
        </div>}
      </div>
    );
  }
}

DataTable.defaultProps = {
  pagination: true,
  scroll: { x: 1500/*, y: 0 */ }
}

export default withLocale(DataTable);
