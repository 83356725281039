import React, { Component } from 'react';
import moment from 'moment';
import service from "../../services/Service"
import { GetColumnLiteralSearchProps } from '../../utils/functions';
import DataTable from '../DataTable/DataTable';
import {
  CheckOutlined, CloseOutlined
} from "@ant-design/icons";
import Loading from "../../utils/loading";

import { withLocale } from '../../l10n';

class BoardsInfoTable extends Component {

  state = {
    loading: true,
    data: [],
    comercial_model_filters: [],
    hardware_model_filters: [],
    daemon_version_filters: [],
    daemon_version_default_filters: []
  };

  async loadData(date) {
    this.setState({ loading: true });
    await service.get("boards/info").then(res => {
      const comercial_models = Array.from(new Set(res.data.map(e => e.info?.comercial_model ?? null)));
      const hardware_models = Array.from(new Set(res.data.map(e => e.info?.board_model ?? null)));
      const daemon_versions = Array.from(new Set(res.data.map(e => e.info?.quiiotd_version ?? null)));

      this.setState({
        loading: false,
        data: res.data,
        comercial_model_filters: comercial_models.map((item) => { return { text: item ?? "Vacio", value: item }; }),
        hardware_model_filters: hardware_models.map((item) => { return { text: item ?? "Vacio", value: item }; }),
        daemon_version_filters: daemon_versions.map((item) => { return { text: item ?? "Vacio", value: item }; }),
        daemon_version_default_filters: daemon_versions.filter(item => item !== null),
      });
    });
  };

  async componentDidMount() {
    this.loadData();
  }

  isUpdated(record) {
    const model_versions = {
      'v0.3-1-80384a9': ['A20', 'TRB245', 'RUT2XX', 'RUT2M', 'RUT9XX', 'RUT9M'],
      'v0.3-1-01cbbac': ['TRB145'],
      'v0.3-1-f99bf4b': ['RT5350']
    };
    return record.info?.quiiotd_version && model_versions[record.info.quiiotd_version]?.includes(record.info.board_model);
  }

  render() {
    const { loading } = this.state;

    const columns = [
      {
        title: this.props.lang.handle("Estado"),
        dataIndex: 'status',
        align: "center",
        render: (text, record) => (
          this.isUpdated(record) ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />
        ),
        filters: [{ text: 'Actualizado', value: true }, { text: 'No actualizado', value: false }],
        onFilter: (value, record) => this.isUpdated(record) === value,
      },
      {
        title: this.props.lang.handle("UUID"),
        dataIndex: 'uuid',
        align: "center",
        ...GetColumnLiteralSearchProps('uuid', this.props.lang)
      },
      {
        title: this.props.lang.handle("Modelo"),
        dataIndex: ['info', 'comercial_model'],
        align: "center",
        filters: this.state.comercial_model_filters,
        onFilter: (value, record) => { const check = record.info?.comercial_model ?? null; return check === value },
      },
      {
        title: this.props.lang.handle("Modelo de hardware"),
        dataIndex: ['info', 'board_model'],
        align: "center",
        filters: this.state.hardware_model_filters,
        onFilter: (value, record) => { const check = record.info?.board_model ?? null; return check === value },
      },
      {
        title: this.props.lang.handle("Fecha de compilación"),
        dataIndex: ['info', 'compilation_date'],
        align: "center",
        sorter: (a, b) => new Date(a.info?.compilation_date) - new Date(b.info?.compilation_date),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: this.props.lang.handle("Versión de Daemon"),
        dataIndex: ['info', 'quiiotd_version'],
        align: "center",
        filters: this.state.daemon_version_filters,
        onFilter: (value, record) => { const check = record.info?.quiiotd_version ?? null; return check === value },
        defaultFilteredValue: this.state.daemon_version_default_filters
      },
      {
        title: this.props.lang.handle('Fecha de actualización'),
        dataIndex: 'update_ts',
        align: "center",
        render: function (data) {
          if (data === null) {
            return null;
          }
          var d = new Date(data);
          if (d !== "Invalid Date") {
            data = moment(d).utc().format('DD-MM-YYYY HH:mm');
          }
          return <p> {data} </p>;
        },
        sorter: (a, b) => new Date(a.update_ts) - new Date(b.update_ts),
        sortDirections: ["descend", "ascend"],
      }
    ];

    return (
      <div className='card card-body'>
        {loading && <Loading loading={loading} />}
        {!loading &&
          <DataTable
            columns={columns}
            dataSource={this.state.data}
            rowKey="uuid"
            loading={loading}
          />
        }
      </div>
    );
  }
}

export default withLocale(BoardsInfoTable);
