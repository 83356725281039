import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import BoardCard from "../components/BoardCard";

export default function EditBoard() {
  const navigate = useNavigate();
  const params = useParams();
  const administration_edit = hasPrivilege('administration_edit') || hasPrivilege('administration_edit_comercial');

  useEffect(() => {
    if (!administration_edit) {
      navigate("/");
    }
  }, [navigate, administration_edit])

  if (administration_edit) {
    const editable = true;
    const ident = params.id;

    return (
      <div>
        <BoardCard editable={editable} ident={ident} />
      </div>
    )
  }
}
