import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import routes from "./config/routes";
import AuthProvider from './providers/AuthProvider'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Locale } from './l10n/locale.context';
import { withLocale } from "./l10n";
import { ConfigProvider } from 'antd';
import frFR from 'antd/locale/fr_FR';
import deDE from 'antd/locale/de_DE';
import esES from 'antd/locale/es_ES';
import enUS from 'antd/locale/en_US';
import ptPT from 'antd/locale/pt_PT';
import itIT from 'antd/locale/it_IT';
function App({ lang }) {

  //Lógica para recoger el idioma del navegador
  if (!localStorage.getItem("langCode")) {
    console.log(localStorage.getItem("langCode"))
    if (navigator.language.includes("es")) {
      localStorage.setItem("langCode", "es");
    } else if (navigator.language.includes("fr")) {
      localStorage.setItem("langCode", "fr");
    } else if (navigator.language.includes("de")) {
      localStorage.setItem("langCode", "de");
    } else if (navigator.language.includes("en")){
      localStorage.setItem("langCode", "en");
    } else if (navigator.language.includes("pt")){
      localStorage.setItem("langCode", "pt");
    }else if (navigator.language.includes("it")){
      localStorage.setItem("langCode", "it");
    }
  }

  if (localStorage.getItem("langCode") === 'es') {
    lang = esES;
  } else if (localStorage.getItem("langCode") === 'fr') {
    lang = frFR;
  } else if (localStorage.getItem("langCode") === 'en') {
    lang = enUS;
  } else if (localStorage.getItem("langCode") === 'de') {
    lang = deDE;
  }else if (localStorage.getItem("langCode") === 'pt') {
    lang = ptPT;
  }else if (localStorage.getItem("langCode") === 'it') {
    lang = itIT;
  }
  
  return (
    <ConfigProvider locale={lang}>
      <Locale>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              {RouteWithSubRoutes(routes)}
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </Locale>
    </ConfigProvider>
  );
}

function RouteWithSubRoutes(routes) {
  return (routes.map((route) => {
    return (
      <Route exact={route.exact} key={route.path} path={route.path} element={route.element}>
        {route.routes && route.routes.length > 0 && RouteWithSubRoutes(route.routes)}
      </Route>
    )
  }));
}

export default withLocale(App);
