import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getTokenInfo } from "../api/token";
import Home from "../components/Home";

export default function Main() {
  const navigate = useNavigate();
  const role_uuid = getTokenInfo().role_uuid;

  useEffect(() => {
    if (!role_uuid) {
      navigate("/login");
    }
  }, [navigate, role_uuid])

  if (role_uuid) {
    return (
      <div>
        <Home />
      </div>
    )
  }
}
