import { useEffect } from 'react';
import UserCard from "../components/UserCard";
import { useNavigate } from "react-router-dom";
import { hasPrivilege } from "../api/token";


export default function CreateUser() {
  const navigate = useNavigate();
  const users_create = hasPrivilege('users_create');

  useEffect(() => {
    if (!users_create) {
      navigate("/");
    }
  }, [navigate, users_create])

  if (users_create) {
    return (
      <div>
        <UserCard />
      </div>
    );
  }
}
