// Layouts
import MainLayout from "../layouts/MainLayout";

// Pages
import Main from '../pages/Main';
import Redirect from '../pages/Redirect';
import BoardList from '../pages/BoardList';
import ManufactureNew from '../pages/ManufactureNew';
import ManufactureList from '../pages/ManufactureList';
import AssignedList from '../pages/AssignedList';
import NewBoard from '../pages/NewBoard';
import Devices from '../pages/Devices';
import Orders from '../pages/Orders';
import FacilityDetails from '../pages/FacilityDetails';
import GestionM2M from '../pages/ManagementM2M';
import VPN_OLD from '../pages/VPN_OLD';
import GLPI from '../pages/GLPI';
import CHAT from '../pages/CHAT';
import PoolList from '../pages/PoolList/PoolList';
import CreateUser from '../pages/CreateUser';
import ListaUsuarios from "../pages/UsersList";
import ManufactureAssign from '../pages/ManufactureAssign';
import ManufactureEdit from '../pages/ManufactureEdit';
import ManufactureEditKiwi from '../pages/ManufactureEditKiwi';
import EditBoard from '../pages/EditBoard';
import EditKiwi from '../pages/EditKiwi';
import EditUser from '../pages/EditUser';
import ChangePassword from '../pages/ChangePassword';
import Privileges from '../pages/Privileges';
import VPNPage from '../pages/VPNPage';
import BoardsInfo from '../pages/BoardsInfo';
import Activities from '../pages/Activities';
import RolesPage from "../pages/RolesPage";
import ConfigRolesPage from "../pages/ConfigRolesPage";
import Settings from "../pages/Settings";

// Others
import Error404 from "../pages/Error404";
import SignIn from "../pages/SignIn";
import Renewal from "../pages/Renewal";

const Layout = (<MainLayout location={window.location} />)

const routes = [
  { path: "*", element: Layout, exact: false, routes: [{ path: "*", element: <Error404 />, exact: true }] },
  {
    path: "/", element: Layout, exact: false,
    routes: [{
      path: "/", element: <Main />, exact: true
      // path: "/", element: <Main navigate={() => { const navigate = useNavigate(); return navigate }} />, exact: true
    },
    {
      path: "redirect/:to/:id?",
      element: <Redirect />,
      exact: true,
    }]
  },
  { path: "login", element: <SignIn />, exact: true },
  {
    path: "boards",
    element: Layout,
    exact: false,
    routes: [
      {
        path: "manufacture/new/:tab?",
        element: <ManufactureNew />,
        exact: true
      },
      {
        path: "manufacture/list/:tab?",
        element: <ManufactureList />,
        exact: true
      },
      {
        path: "manufacture/edit/1/:id",
        element: <ManufactureEdit />,
        exact: true,
      },
      {
        path: "manufacture/edit/2/:id",
        element: <ManufactureEditKiwi />,
        exact: true,
      },
      {
        path: "manufacture/assign/:id",
        element: <ManufactureAssign />,
        exact: true,
      },
      {
        path: "manufacture/assigned/:tab?",
        element: <AssignedList />,
        exact: true,
      },
      {
        path: "/boards/new/:tab?",
        element: <NewBoard />,
        exact: true,
      },
      {
        path: "/boards/list/:tab?",
        element: <BoardList />,
        exact: true,
      },
      {
        path: "/boards/renewals/:tab?",
        element: <Renewal />,
        exact: true,
      },
      {
        path: "/boards/edit/1/:id",
        element: <EditBoard />,
        exact: true,
      },
      {
        path: "/boards/edit/2/:id",
        element: <EditKiwi />,
        exact: true,
      },
      {
        path: "/boards/assing/:id",
        element: <EditKiwi />,
        exact: true,
      },
    ]
  },
  {
    path: "/devices",
    element: Layout,
    exact: false,
    routes: [
      {
        path: "list",
        element: <Devices/>,
        exact: true,
      },
      {
        path: "details/:id",
        element: <FacilityDetails />,
        exact: true,
      },
    ]
  },
  {
    path: "/orders",
    element: Layout,
    exact: false,
    routes: [
      {
        path: "list",
        element: <Orders />,
        exact: true,
      },
    ]
  },
  {
    path: "/simcards",
    element: Layout,
    exact: false,
    routes: [
      {
        path: "m2m/:id?",
        element: <GestionM2M />,
        exact: true,
      },
      {
        path: "pools",
        element: <PoolList />,
        exact: true,
      },
    ]
  },
  {
    path: "/vpn",
    element: Layout,
    exact: false,
    routes: [
      {
        path: "",
        element: <VPN_OLD />,
        exact: true,
      },
    ]
  },
  {
    path: "/support",
    element: Layout,
    exact: false,
    routes: [
      {
        path: "",
        element: <GLPI />,
        exact: true,
      },
    ]
  },
  {
    path: "/chat",
    element: Layout,
    exact: false,
    routes: [
      {
        path: "",
        element: <CHAT />,
        exact: true,
      },
    ]
  },
  {
    path: "/users",
    exact: false,
    element: Layout,
    routes: [
      {
        path: "create",
        element: <CreateUser />,
        exact: true,
      },
      {
        path: "list",
        element: <ListaUsuarios />,
        exact: true,
      },
      {
        path: "edit/:uuid",
        element: <EditUser />,
        exact: true,
      },
      {
        path: "changepassword/:uuid",
        element: <ChangePassword />,
        exact: true,
      },
      {
        path: "roles",
        element: <RolesPage />,
        exact: true,
      },
      {
        path: "roles/config",
        element: <ConfigRolesPage />,
        exact: true,
      },
      {
        path: "roles/config/:uuid",
        element: <ConfigRolesPage />,
        exact: true,
      },
    ]
  },
  {
    path: "/system",
    exact: false,
    element: Layout,
    routes: [
      {
        path: "privileges",
        element: <Privileges />,
        exact: true,
      },
      {
        path: "vpn",
        element: <VPNPage />,
        exact: true,
      },
      {
        path: "info",
        element: <BoardsInfo />,
        exact: true,
      },
      {
        path: "activities",
        element: <Activities />,
        exact: true,
      },
      {
        path: "settings/:tab?",
        element: <Settings />,
        exact: true,
      },
    ]
  },
];

export default routes;
