import React from "react";
import { Select } from "antd";
import { withLocale } from '../../l10n';
class FilterColumns extends React.Component {
  state = {
    lang: this.props.lang
  };

  render() {
    const allColumns = this.props.columns;
    const { lang } = this.state;
    const options = [];

    allColumns.forEach(c => {
      options.push({
        label: c.title,
        value: Array.isArray(c.dataIndex) ? c.dataIndex.join('.') : c.dataIndex,
      });
    });

    const handleChange = (value) => {
      const result = [];
      allColumns.forEach(c => {
        if (Array.isArray(c.dataIndex) ? value.includes(c.dataIndex.join('.')) : value.includes(c.dataIndex)) {
          result.push(c);
        }
      });
      this.props.changeColumns(result);
    };

    return (
      <div>
        <form>
          <span> {lang.handle('Columnas') + ":"} </span>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '80%' }}
            onChange={handleChange}
            defaultValue={options}
            options={options}
            maxTagCount={5}
          />
        </form>
      </div >
    );
  }
}

export default withLocale (FilterColumns);
