import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import BoardCard from "../components/BoardCard";
import M2MDetails from "../components/m2mDetails";
import { withLocale } from './../l10n';

function FacilityDetails(props) {
  const navigate = useNavigate();
  const params = useParams();
  const mykiconex_view = hasPrivilege('mykiconex_view');

  useEffect(() => {
    if (!mykiconex_view) {
      navigate("/");
    }
  }, [navigate, mykiconex_view])

  if (mykiconex_view) {
    const ident = params.id;

    return (
      <div>
        <h1> {props.lang.handle('Detalles Administrativos')} </h1>
        <BoardCard
          ident={ident}
          details={true}
        />
        <h1> {props.lang.handle('Detalles M2M')} </h1>
        <M2MDetails
          identUUID={ident}
        />
      </div>
    )
  }
}

export default withLocale(FacilityDetails);
