import React from "react";
import { Link } from "react-router-dom";
import { hasPrivilege } from "../../api/token";

import "./Home.scss";
import { withLocale } from './../../l10n/index.js';
function Home(props) {
  const URL = "/boards/manufacture/new";

  if (hasPrivilege('manufacture_create')) {
    return (
      <div>
        <Link to={URL}>
          <button type="button" className="btn btn-primary btn-lg btn-block btnHome" > {props.lang.handle("Crear nuevo dispositivo")} </button>
        </Link>
      </div>
    );
  } else {
    return (
      <div>
        <h1>{props.lang.handle("Bienvenido a kicore ¡la plataforma de gestión de Kiconex!")}</h1>
      </div>
    );
  }
}

export default withLocale(Home);
