import React from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { getTokenInfo, hasPrivilege } from "../api/token";
import { Tabs } from "antd";
import KiboxList from "../components/KiboxList";
import KiwiList from "../components/KiwiList";
import { withLocale } from './../l10n';
function BoardList(props) {
  const navigate = useNavigate();
  const administration_view = hasPrivilege('administration_view');

  useEffect(() => {
    if (!administration_view) {
      navigate("/");
    }
  }, [navigate, administration_view])

  const params = useParams();
  const tenant_uuid = getTokenInfo().tenant_uuid;

  const items = [
    { label: props.lang.handle('Lista kibox'), key: '1', children: <KiboxList userTenant={tenant_uuid} /> },
    { label: props.lang.handle('Lista kiwi'), key: '2', children: <KiwiList userTenant={tenant_uuid} /> },
  ]

  if (administration_view) {
    return (
      <div>
        <Tabs
          type="card"
          activeKey={params.tab}
          items={items}
          onChange={key => {
            navigate(`/boards/list/${key}`)
          }}
        />
      </div>
    )
  }
}

export default withLocale(BoardList);
