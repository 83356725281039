import React from 'react';
import { notification, Input, Button, Space, Modal, Select, Tooltip, Tag } from 'antd';
import { SearchOutlined, PlusOutlined, KeyOutlined, CopyOutlined, FormOutlined, CloudDownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import "./VPN.scss";
import service from '../../services/Service';
import { getTokenInfo, hasPrivilege } from '../../api/token';
import { noWhiteSpacesValidation, RUTSerialNumberValidation, RUTUuidValidation, usernameValidation, uuidValidateV4 } from "../../utils/formValidation";
import { withLocale } from '../../l10n';
import DataTable from '../DataTable/DataTable';
import { getFormatDate } from '../../utils/functions';
import { REFRESH_INTERVAL_MILISECONDS } from '../../utils/constants';
import DeleteButton from '../DeleteButton/DeleteButton';

class VPN extends React.Component {
  state = {
    data: [],
    botonDisabled: true,
    loading: false,
    lang: this.props.lang,
    editPassword: false,
    showModal: false,
    username: null,
    password: null,
    tenant: null,
    isNew: false,
    intervalId: null,
    tenants: []
  };

  getColumnSearchProps = (dataIndex, lang) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {lang.handle('Buscar')}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            {lang.handle('Borrar')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  async componentDidMount() {
    this.setState({ loading: true });

    await service.get('tenants')
      .then(async res => {
        this.setState({
          tenants: res.data.map((tenant) => { return { text: tenant.name, value: tenant.uuid } })
        })
      });

    this.fetch();

    const intervalId = setInterval(this.fetch, REFRESH_INTERVAL_MILISECONDS);
    this.setState({
      loading: false,
      intervalId
    })
  }

  componentWillUnmount() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }

  fetch = async (params = {}) => {
    service.get('vpn', { status: true })
      .then(res => {
        let temp_data = [];
        if (!res || res.data.ok === false) {
          notification["error"]({
            message: this.props.lang.handle(res.data.message),
          });
          temp_data = [];
        } else {
          temp_data = res.data;
        }

        this.setState({
          data: temp_data,
        });
      });
  };

  createVPN = async () => {
    this.generateRandomPassword();
    this.setState({ isNew: true, showModal: true });
  }

  editVPN = async () => {
    if (this.state.selected.type === 'user') {
      this.setState({ loading: true });
      window.location.href = '/users/edit/' + this.state.selected.username;
      return;
    }

    this.setState({ username: this.state.selected.username, tenant: this.state.selected.tenant, showModal: true });
  }

  changePassword = async () => {
    if (this.state.selected.type === 'user') {
      this.setState({ loading: true });
      window.location.href = '/users/changepassword/' + this.state.selected.username;
      return;
    }

    this.generateRandomPassword();
    this.setState({ username: this.state.selected.username, editPassword: true, showModal: true });
  }

  deleteVPN = async () => {
    await service
      .del(`vpn/${this.state.selected.username}`)
      .then((res) => {
        notification["success"]({
          message: this.props.lang.handle(res.data.message),
        });
        this.fetch();
      })
      .catch((err) => {
        console.log("Error devuelto: " + err);
      });
  }

  handleClose = () => {
    this.setState({ isNew: false, username: null, tenant: null, editPassword: false, showModal: false });
  };

  validate = () => {
    const { username, editPassword, password, tenant } = this.state;
    const lang = this.props.lang;

    if (!noWhiteSpacesValidation(username) || (!uuidValidateV4(username) && !RUTUuidValidation(username) && !RUTSerialNumberValidation(username) && !usernameValidation(username))) {
      notification["error"]({
        message: lang.handle('¡Usuario erróneo!'),
      });
      return false;
    }
    if (editPassword && !password) {
      notification["error"]({
        message: lang.handle('¡Contraseña no válida!'),
      });
      return false;
    }
    if (!editPassword && !tenant) {
      notification["error"]({
        message: lang.handle('¡Debe seleccionar una organización!'),
      });
      return false;
    }

    return true;
  }

  onSubmit = async () => {
    const { username, editPassword, password, tenant, isNew } = this.state;
    const lang = this.props.lang;
    if (!this.validate()) {
      return
    }
    if (isNew) {
      await service
        .post("vpn", { username, password, tenant })
        .then((res) => {
          if (res.data.ok === false) {
            notification["error"]({
              message: lang.handle(res.data.message),
            });
            return;
          } else {
            notification["success"]({
              message: lang.handle(res.data.message),
            });
            this.handleClose();
          }
        })
        .catch((err) => {
          console.log("Error devuelto: " + err);
        });
    } else {
      const data = editPassword ? { password } : { username, tenant };
      await service
        .put("vpn/" + this.state.selected.username, data)
        .then((res) => {
          if (res.data.ok === false) {
            notification["error"]({
              message: lang.handle(res.data.message),
            });
            return;
          } else {
            notification["success"]({
              message: lang.handle('VPN actualizada correctamente'),
            });
            this.handleClose();
          }
        })
        .catch((err) => {
          console.log("Error devuelto: " + err);
        });
    }
    this.fetch();
  };

  onInputChange = (e) => {
    const { value } = e.target;
    this.setState({ username: value })
  }

  onInputPasswordChange = (e) => {
    const { value } = e.target;
    this.setState({ password: value })
  }

  onInputTenantChange = (value) => {
    this.setState({ tenant: value })
  }

  generateRandomPassword = () => {
    const length = 16;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    this.setState({ password: result });
  };

  copyToClipboard = () => {
    navigator.clipboard.writeText(this.state.password).then(() => {
      notification["success"]({
        message: this.props.lang.handle('Contraseña copiada al portapapeles'),
      });
    }).catch(err => {
      console.log(err)
      notification["error"]({
        message: this.props.lang.handle('Fallo al copiar la contraseña'),
      });
    });
  };

  downloadVPNFile = async () => {
    await service
      .post(`vpn/${getTokenInfo().uuid}`)
      .then(async (res) => {
        if (res) {
          await service.download(`vpn/${getTokenInfo().uuid}/download`, process.env.REACT_APP_VPN_FILENAME);
        }
      });
  }

  render() {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          botonDisabled: false,
          selected: selectedRows[0],
        });
      }
    };

    const { lang, tenants } = this.state;

    const options = [
      {
        label: 'KICONEX',
        value: '90be8c8a-f462-4a3e-afcf-d8f34094eaa8' //0
      },
      {
        label: 'INTARCON',
        value: '54da349f-b026-4e4c-a571-2efe9eb644ed' //1
      },
      {
        label: 'KEYTER',
        value: 'edf19151-34fa-4385-878a-b725d8450763' //2
      },
      {
        label: 'GENAQ',
        value: '504549ae-bee1-4f5f-abc1-7c4fbd6b0c20' //3
      },
      {
        label: 'SEGUAS',
        value: 'b0c3d8f6-a126-4c74-aff9-e1f77e95d7b9' //4
      },
      {
        label: 'FMGRUPO',
        value: 'c5557dfe-7eba-455a-b224-dac06dea3cd9' //5
      },
    ]

    const columns = [
      {
        title: lang.handle("Usuario"),
        dataIndex: 'username',
        ...this.getColumnSearchProps('username', lang)
      },
      {
        title: lang.handle('Organización'),
        dataIndex: 'tenant',
        filters: tenants,
        onFilter: (value, record) => record.tenant === value,
        render: (data) => { return tenants.find(e => e.value === data).text }
      },
      {
        title: 'IP',
        dataIndex: 'framedipaddress',
        ...this.getColumnSearchProps('framedipaddress', lang)
      },
      {
        title: lang.handle('Estado'),
        dataIndex: 'status',
        filters: [
          { text: lang.handle('Conectada'), value: true },
          { text: lang.handle('Desconectada'), value: false }
        ],
        onFilter: (value, record) => record.status.detected === value,
        render: function (data) {
          const color = data.detected ? 'green' : 'red';
          const message = data.detected ? lang.handle('Conectada') : lang.handle('Desconectada');
          const date = getFormatDate(data.lastchange);
          return <Tooltip title={date !== '' ? date : lang.handle('Nunca')}> <Tag color={color}> {message} </Tag> </Tooltip>;
        }
      }
    ];
    if (hasPrivilege('vpn_admin')) {
      columns.push(
        {
          title: lang.handle('Tipo'),
          dataIndex: 'type',
          align: "center",
          filters: [{ text: lang.handle('Usuario'), value: 'user' }, { text: lang.handle('Dispositivo'), value: 'device' }],
          onFilter: (value, record) => { const check = record.type ?? null; return check === value },
          defaultFilteredValue: ['device'],
          render: function (data) {
            if (data === null) {
              return null;
            }
            return <p> {data === 'user' ? lang.handle('Usuario') : lang.handle('Dispositivo')} </p>;
          },
        }
      );
    }

    const { data, loading, password, username, tenant, isNew, editPassword } = this.state;

    return (
      <div>
        {hasPrivilege('vpn_admin') &&
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={this.createVPN}
          >
            {lang.handle('Nueva conexión VPN')}
          </Button>
        }
        {hasPrivilege('vpn_view') &&
          <Button
            style={{ float: 'right', marginBottom: '20px' }}
            type="primary"
            icon={<CloudDownloadOutlined />}
            onClick={this.downloadVPNFile}
          >
            {lang.handle('Descargar fichero de configuración')}
          </Button>
        }
        <DataTable
          rowSelection={{
            type: "radio",
            ...rowSelection
          }}
          columns={columns}
          dataSource={data}
          rowKey="username"
          loading={loading}
          scroll={null}
        />
        <div className="button-panel">
          <Space>
            {hasPrivilege('vpn_admin') && <Button
              type="primary"
              icon={<FormOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.editVPN}
            >
              {lang.handle('Editar')}
            </Button>}
            {hasPrivilege('vpn_admin') && <Button
              type="primary"
              icon={<FormOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.changePassword}
            >
              {lang.handle('Regenerar contraseña')}
            </Button>}

            {hasPrivilege('manufacture_delete') &&
              <DeleteButton
                onEvent={this.deleteVPN}
                buttonDisabled={this.state.botonDisabled}
              />}

          </Space>
        </div>

        <Modal
          title={lang.handle('Contraseña VPN')}
          onCancel={this.handleClose}
          cancelText={lang.handle('Cerrar')}
          onOk={this.onSubmit}
          okText={lang.handle('Guardar')}
          icon={<KeyOutlined />}
          open={this.state.showModal}
          width={600}
        >
          {lang.handle('Copie la contraseña antes de guardar, no volverá a ser visible')}
          <form onSubmit={this.onSubmit} className="p-3">
            <div className="form-group row">
              <label htmlFor="username"> {lang.handle('Usuario')}
                <Tooltip placement="top" title={lang.handle('Formatos correctos: UUID, RUT-UUID, RUT-SN. También cadena de texto minúscula de entre 6 y 16 caracteres.')}>
                  <InfoCircleOutlined style={{ marginLeft: 8 }} />
                </Tooltip>
              </label>
              <Input name='username' value={username} className='col' onChange={this.onInputChange} readOnly={editPassword} />
            </div>

            {(editPassword || isNew) && <div className="form-group row">
              <label htmlFor="password"> {lang.handle('Contraseña')} </label>
              <Input.Password name='password' value={password} onChange={this.onInputPasswordChange} className='col' />
              <Button onClick={this.copyToClipboard} disabled={password === null} className='col-2'><CopyOutlined /></Button>
            </div>}

            {!editPassword && <div className="form-group row">
              <label htmlFor="tenant"> {lang.handle('Organización')} </label>
              <Select
                className="form-control"
                onChange={this.onInputTenantChange}
                value={tenant}
                options={options}
              />
            </div>}
          </form>

        </Modal>
      </div>
    );
  }
}

export default withLocale(VPN);
