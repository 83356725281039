import React, { Component } from 'react';
import moment from 'moment';
import { notification, DatePicker } from 'antd';
import service from "../../services/Service"
import dayjs from 'dayjs';
import { GetColumnLiteralSearchProps } from '../../utils/functions';
import DataTable from '../DataTable/DataTable';

import { withLocale } from '../../l10n';

const { RangePicker } = DatePicker;

class ActivitiesTable extends Component {

  ACTIVITY_ACTIONS = [
    {
      text: this.props.lang.handle("Creación"),
      value: 'create'
    },
    {
      text: this.props.lang.handle("Actualización"),
      value: 'update'
    },
    {
      text: this.props.lang.handle("Borrado"),
      value: 'delete'
    },
    {
      text: this.props.lang.handle("Cambio de estado"),
      value: 'change-status'
    },
    {
      text: this.props.lang.handle("Cambio de contraseña"),
      value: 'change-password'
    },
    {
      text: this.props.lang.handle("Sincronización"),
      value: 'sync'
    },
    {
      text: this.props.lang.handle("Comienzo Personificación"),
      value: 'impersonate-on'
    },
    {
      text: this.props.lang.handle("Fin Personificación"),
      value: 'impersonate-off'
    },
  ];

  ACTIVITY_TYPES = [
    {
      text: "Kibox",
      value: 'board'
    },
    {
      text: "Kiwi",
      value: 'kiwi'
    },
    {
      text: this.props.lang.handle("Usuario"),
      value: 'user'
    },
    {
      text: this.props.lang.handle("Pedido"),
      value: 'order'
    },
    {
      text: this.props.lang.handle("Orden de trabajo"),
      value: 'workorder'
    },
    {
      text: "M2M",
      value: 'm2m'
    },
    {
      text: "VPN",
      value: 'vpn'
    },
    {
      text: this.props.lang.handle("Rol"),
      value: 'role'
    },
    {
      text: this.props.lang.handle("Privilegio"),
      value: 'privilege'
    },
    {
      text: this.props.lang.handle("Organización"),
      value: 'tenant'
    },
  ];

  state = {
    loading: true,
    data: [],
    actions: this.ACTIVITY_ACTIONS,
    types: this.ACTIVITY_TYPES
  };

  async loadData(date) {
    this.setState({ loading: true });
    await service.post("activities", date).then(res => {
      this.setState({ loading: false, data: res.data })
      notification["success"]({ message: "Registros Cargados" });
    });
  };

  async componentDidMount() {
    let date = { from: moment().subtract(7, 'day'), until: moment() };
    date.from = this.getDate(date.from['_d']);
    date.until = this.getDate(date.until['_d']);

    this.loadData(date);
  }

  getDate(date) { return moment(date).format('YYYY-MM-DD HH:mm:ss') }

  getTextAction(value) {
    return this.state.actions.find(el => el.value === value).text;
  }

  getTextType(value) {
    return this.state.types.find(el => el.value === value).text;
  }

  getIdentifier(value) {
    if (value.includes('list')) {
      return this.props.lang.handle('Listado');
    }

    return value;
  }

  render() {
    const { loading } = this.state;

    const columns = [
      {
        title: this.props.lang.handle('Fecha'),
        dataIndex: 'create_ts',
        width: "10%",
        align: "center",
        render: function (data) {
          if (data === null) {
            return null;
          }
          var d = new Date(data);
          if (d !== "Invalid Date") {
            data = moment(d).utc().format('DD-MM-YYYY HH:mm');
          }
          return <p> {data} </p>;
        },
      },
      {
        title: this.props.lang.handle("Nombre de usuario"),
        dataIndex: ["metadata", "agent_name"],
        width: "10%",
        align: "center",
        ...GetColumnLiteralSearchProps(["metadata", "agent_name"], this.props.lang)
      },
      {
        title: this.props.lang.handle('Acción'),
        dataIndex: 'operation',
        width: "10%",
        filters: this.state.actions,
        onFilter: (value, record) => record.operation === value,
        render: (data) => { return this.getTextAction(data); }
      },
      {
        title: this.props.lang.handle('Tipo de objeto'),
        dataIndex: ["metadata", "type"], width: "10%",
        filters: this.state.types,
        onFilter: (value, record) => record.metadata.type === value,
        render: (data) => { return this.getTextType(data); }
      },
      {
        title: this.props.lang.handle('Identificador'),
        dataIndex: 'target',
        ...GetColumnLiteralSearchProps("target", this.props.lang),
        render: (data) => { return this.getIdentifier(data) }
      },
      {
        title: this.props.lang.handle('Nombre'),
        dataIndex: ['metadata', 'target_name'],
        ...GetColumnLiteralSearchProps(["metadata", "agent_name"], this.props.lang)
      },
    ];

    const rangePresets = [
      {
        label: this.props.lang.handle('Hace 3 horas'),
        value: [dayjs().subtract(3, 'hour'), dayjs()],
      },
      {
        label: this.props.lang.handle('Hace 1 día'),
        value: [dayjs().subtract(1, 'day'), dayjs()],
      },
      {
        label: this.props.lang.handle('Hace 3 días'),
        value: [dayjs().subtract(3, 'day'), dayjs()],
      },
      {
        label: this.props.lang.handle('Hace 1 semana'),
        value: [dayjs().subtract(1, 'week'), dayjs()],
      }
    ];

    const onRangeChange = (dates, dateStrings) => {
      const date = { from: dateStrings[0], until: dateStrings[1] };
      this.loadData(date);
    };

    return (
      <div className='card card-body'>
        <div className="row mt-4 mb-3">
          <div className="col-6">
            <RangePicker
              defaultValue={[dayjs(), dayjs().subtract(7, 'day')]}
              presets={rangePresets}
              style={{ width: '100%' }}
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              size="large"
              onChange={onRangeChange}
            />
          </div>
        </div>
        <DataTable
          columns={columns}
          dataSource={this.state.data}
          rowKey="id"
          loading={loading}
          scroll={{ x: true, y: 600 }}
          pagination={false}
        />
      </div>
    );
  }
}

export default withLocale(ActivitiesTable);
