import React, { useState, useEffect, createContext } from "react";
import { jwtDecode } from "jwt-decode";
import {
  refreshAccessTokenApi,
  getRefreshToken,
  getAccessToken,
} from "../api/token";
import { getTokenInfo } from "../api/token";

import { logout } from "../api/user";

export const AuthContext = createContext();

export default function AuthProvider(props) {
  // childer es toda la página web, independientemente de en qué parte de ella estemos
  const { children } = props;
  // objeto usuario, por defecto deslogeado (null)
  const [user, setUser] = useState({
    user: null,
    role_uuid: null,
    is_admin: null,
    isLoading: true,
  });

  useEffect(() => {
    checkUserLogin(setUser);
  }, []);

  // devuelve el contexto 
  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}

function checkUserLogin(setUser) {
  const accessToken = getAccessToken();

  if (!accessToken) {
    const refreshToken = getRefreshToken();

    if (!refreshToken) {
      logout();
      setUser({
        user: null,
        role_uuid: null,
        is_admin: null,
        privileges: [],
        isLoading: false,
      });
    } else {
      refreshAccessTokenApi(refreshToken);
    }
  } else {
    setUser({
      isLoading: false,
      role_uuid: getTokenInfo().role_uuid,
      is_admin: getTokenInfo().is_admin,
      user: jwtDecode(accessToken),
    });
  }
}