import React, { Component } from "react";
import "./Renewals.scss";
import { Space, Button, Input, DatePicker, Select, Checkbox } from "antd";
import { withLocale } from "../../l10n";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import service from "../../services/Service";
import get from 'lodash/get';
import isequal from 'lodash/isEqual';
import moment from "moment";
import Excelexport from "../../utils/excelexport";
import dayjs from 'dayjs';
import { STATE_ACTIVE, STATE_CANCELLED, STATE_EXPIRED, STATE_INACTIVE, STATE_NOT_APPLICABLE, SUBSCRIPTIONS } from "../../utils/constants";
import DataTable from "../DataTable/DataTable";
import { subscribe, unsubscribe } from "../../utils/events";
const { RangePicker } = DatePicker;


class Renewals extends Component {

  state = {
    columns: [],
    tenantsFilter: [],
    datos: [],
    renewals: [],
    renewalsFilter: [],
    loading: false,
    botonDisabled: true,
    selectedId: "",
    tenants: [],
    lang: this.props.lang
  };

  params = {
    from: moment().format("YYYY-MM-DD"),
    to: moment().add(1, 'month').format("YYYY-MM-DD"),
    filtered: "",
    showAll: false
  }

  async componentDidMount() {
    subscribe("datatable-filter", (data) => {
      const renewalsFilter = data.detail;
      this.setState({
        renewalsFilter
      })
    })

    this.setState({ loading: true });
    if (this.props.location.state) {
      this.params.from = "";
      this.params.to = "";
      this.params.filtered = this.props.location.state.plan;
      this.params.showAll = false;
    }

    await service.get('tenants')
      .then(res => {
        var filter = [];
        res.data.forEach(tenant => {
          filter.push({ text: tenant.name, value: tenant.uuid })
        });

        this.setState({
          tenants: res.data,
          tenantsFilter: filter
        })
        this.Pfetch(this.params);
      });

  }

  componentWillUnmount() {
    unsubscribe('datatable-filter')
  }

  getColumnSearchProps = (dataIndex, lang) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {lang.handle('Buscar')}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {lang.handle('Borrar')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      get(record, dataIndex)
        ? get(record, dataIndex)
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      isequal(this.state.searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  Pfetch = async (params = {}) => {
    let array = [];
    this.setState({ loading: true });

    await service
      .get("boards/renewals", params)
      .then((res) => {
        if (res.status === 200) {
          array = res.data;
          for (const kibox of array) {
            kibox.date_to_renew = moment(kibox.date_to_renew).format('DD-MM-YYYY');
            kibox.renewal_diff = Math.round((kibox.renewal_diff + Number.EPSILON) * 100) / 100

            const tenant = this.state.tenants.find((item) => item.uuid === kibox.tenant_uuid);
            if (kibox.tenant_uuid && tenant) {
              kibox.tenant_name = tenant.name;
            } else {
              kibox.tenant_name = this.props.lang.handle("Sin organización");
            }
          }
          this.setState({
            loading: false,
            renewals: array,
            renewalsFilter: array
          });
        }
      })
      .catch((err) => {
        console.log("Error devuelto: " + err);
      });
  }

  render() {
    const { renewals, renewalsFilter, loading, lang } = this.state;
    const columns = [
      {
        title: this.props.lang.handle('Pedido'),
        dataIndex: "order_id",
        ...this.getColumnSearchProps("order_id", this.props.lang),
      },
      {
        title: this.props.lang.handle('UUID'),
        dataIndex: "uuid",
        ...this.getColumnSearchProps("uuid", this.props.lang),
      },
      {
        title: this.props.lang.handle('Organización'),
        dataIndex: "tenant_name",
        filters: this.state.tenantsFilter,
        onFilter: (value, record) => record.tenant_uuid === value,
      },
      {
        title: this.props.lang.handle('Fecha Renovación'),
        dataIndex: ["date_to_renew"],
        defaultSortOrder: "ascend",
        sorter: (a, b) => moment(a.date_to_renew, 'DD-MM-YYYY').diff(moment(b.date_to_renew, 'DD-MM-YYYY')),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: this.props.lang.handle('Estado'),
        dataIndex: ["renewal_diff"],
        filters: [
          { text: this.props.lang.handle("Caducado"), value: STATE_EXPIRED },
          { text: this.props.lang.handle("Inactivo"), value: STATE_INACTIVE },
          { text: this.props.lang.handle("Activo"), value: STATE_ACTIVE },
          { text: this.props.lang.handle("Cancelado"), value: STATE_CANCELLED },
          { text: this.props.lang.handle("No procede"), value: STATE_NOT_APPLICABLE },
        ],
        onFilter: (value, record) =>
          record.state && record.state.toLowerCase() === value.toLowerCase(),
        render: function (data, record) {
          let label = "";
          switch (record.state) {
            case STATE_EXPIRED:
              label = lang.handle("Caducado");
              break;
            case STATE_INACTIVE:
              label = lang.handle('Inactivo');
              break;
            case STATE_ACTIVE:
              label = lang.handle('Activo');
              break;
            case STATE_CANCELLED:
              label = lang.handle('Cancelado');
              break;
            case STATE_NOT_APPLICABLE:
              label = lang.handle('No procede');
              break;
            default:
              label = 'error';
              break;
          }
          if (data === null) {
            return null;
          } else {
            if (record.state === STATE_ACTIVE) {
              return data < 0 ? <p> {lang.handle('Caducada')} </p> : <p> {lang.handle('Activa')} - {Math.floor(data) + lang.handle(" días restantes")} </p>;
            } else {
              return <p>{label}</p>
            }
          }
        },
      },
    ];

    if (this.params.filtered === 'm2m') {
      columns.splice(2, 0,
        {
          title: 'ICC',
          dataIndex: "icc",
          ...this.getColumnSearchProps("icc", this.props.lang),
        }
      );
    }

    const rangePresets = [
      {
        label: this.props.lang.handle('Próximo mes'),
        value: [dayjs(), dayjs().add(1, 'month')],
      },
      {
        label: this.props.lang.handle('Próximos 3 meses'),
        value: [dayjs(), dayjs().add(3, 'month')],
      },
      {
        label: this.props.lang.handle('Próximos 6 meses'),
        value: [dayjs(), dayjs().add(6, 'month')],
      },
      {
        label: this.props.lang.handle('Próximo año'),
        value: [dayjs(), dayjs().add(1, 'year')],
      },
    ];

    const onRangeChange = (dates, dateStrings) => {
      if (dateStrings || dateStrings[0] === '') {
        this.params.from = dateStrings[0];
        this.params.to = dateStrings[1];
        this.Pfetch(this.params)
      }
    };

    const onChangeCheck = value => {
      this.params.showAll = !this.params.showAll;
      this.Pfetch(this.params);
    };

    let options = SUBSCRIPTIONS.map((item) => ({ label: item.toUpperCase(), value: item }))
    const defaultOption = { label: "PLAN", value: "" }
    options.push(defaultOption);

    const handleChange = (value) => {
      if (value || value === '') {
        this.params.filtered = value;
        this.Pfetch(this.params)
      }
    };

    return (
      <div>
        <div className="d-flex justify-content-around mb-4 row">
          <div className="col-md-3">
            <Select
              className="select-plan"
              defaultValue={this.props.location.state ? { label: (!this.props.location.state.plan || this.props.location.state.plan === "") ? "PLAN" : this.props.location.state.plan.toUpperCase(), value: this.props.location.state.plan } : defaultOption}
              size="large"
              options={options}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4">
            <RangePicker
              defaultValue={this.props.location.state ? ["", ""] : [dayjs(), dayjs().add(1, 'month')]}
              presets={rangePresets}
              format="YYYY-MM-DD"
              size="large"
              onChange={onRangeChange}
            />
          </div>
          <div className="col-md-4">
            <Checkbox
              name="showAll"
              checked={this.params.showAll}
              onClick={onChangeCheck}
            >
              {lang.handle('Mostrar todo')}
            </Checkbox>
          </div>
        </div>
        <div>
          <DataTable
            columns={columns}
            rowKey="uuid"
            dataSource={renewals}
            loading={loading}
          />
          <div className="button-panel mt-4">
            <Space>
              <Excelexport
                fileName={((!this.params.filtered || this.params.filtered === '') ? 'PLAN' : this.params.filtered.toUpperCase()) + "-" + (this.params.from === '' ? 'EXPIRED' : this.params.from + "-" + this.params.to)}
                fileData={renewalsFilter}
                label={this.props.lang.handle('Descargar Informe')}
              />
            </Space>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocale(Renewals);
