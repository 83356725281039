import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import { Tabs } from "antd";
import KiboxManufactureList from "../components/KiboxManufactureList";
import KiwiManufactureList from "../components/KiwiManufactureList";
import { withLocale } from './../l10n';
function ManufactureList(props) {
  const navigate = useNavigate();
  const manufacture_view = hasPrivilege('manufacture_view');

  useEffect(() => {
    if (!manufacture_view) {
      navigate("/");
    }
  }, [navigate, manufacture_view])

  const params = useParams();

  const items = [
    { label: props.lang.handle('Lista kibox'), key: '1', children: <KiboxManufactureList /> },
    { label: props.lang.handle('Lista kiwi'), key: '2', children: <KiwiManufactureList /> },
  ]

  if (manufacture_view) {
    return (<Tabs
      type="card"
      activeKey={params.tab}
      items={items}
      onChange={key => {
        navigate(`/boards/manufacture/list/${key}`)
      }} />)
  }
}

export default withLocale(ManufactureList);
