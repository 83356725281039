import service from "../services/Service";

export function getBoardsByPED(order_id) {
  return service
    .get("boards")
    .then((resp) => resp.data)
    .then((respObj) => {
      let boards = [];
      for (let i = 0; i < respObj.length; i++) {
        if (respObj[i].hasOwnProperty("order_id")) {
          if (respObj[i].order_id !== null) {
            if (respObj[i].order_id.includes(order_id) && !boards.find((item) => item === respObj[i].uuid)) {
              boards.push(respObj[i].uuid);
            }
          }
        }
      }
      if (boards.length === 0) {
        for (let i = 0; i < respObj.length; i++) {
          boards.push(respObj[i].uuid);
        }
        return boards;
      } else {
        return boards;
      }
    })
    .catch((err) => console.log(err));
}
