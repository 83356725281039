import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import PrivilegesTable from '../components/PrivilegesTable';

export default function Privileges() {
  const navigate = useNavigate();
  const privileges_view = hasPrivilege('privileges_view');

  useEffect(() => {
    if (!privileges_view) {
      navigate("/");
    }
  }, [navigate, privileges_view])

  if (privileges_view) {
    return (
      <div>
        <PrivilegesTable />
      </div>
    )
  }
}
