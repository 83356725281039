export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const API_TOKEN = "apiToken";
export const PRIVILEGES_TOKEN = "privilegesToken";
export const SUBSCRIPTIONS = ['sms', 'branding', 'm2m'];
export const REFRESH_INTERVAL_MILISECONDS = 10000;//10seg
export const REFRESH_INTERVAL_VERSION_MILISECONDS = 600000;//10min

export const STATE_EXPIRED = 'expired';
export const STATE_INACTIVE = 'inactive';
export const STATE_ACTIVE = 'active';
export const STATE_CANCELLED = 'cancelled';
export const STATE_NOT_APPLICABLE = 'not-applicable';
