import { notification } from 'antd';
import axios from 'axios';
import { API_TOKEN } from "../utils/constants";

const URL = process.env.REACT_APP_API_URL;

class Service {
  constructor(api_url) {
    this.baseUrl = api_url;
  }

  get = async (url, params = null) => {
    try {
      var config = {
        method: 'get',
        url: this.baseUrl + url,
        headers: {
          'Authorization': 'Basic ' + localStorage.getItem(API_TOKEN),
          'Content-Type': 'application/json'
        },
        params
      };
      return await axios(config)
    } catch (err) {
      if (err.response?.data.message) {
        notification["error"]({
          message: err.response?.data.message,
        });
      }
    }
  }

  download = async (url, filename) => {
    try {
      var config = {
        url: this.baseUrl + url,
        method: 'GET',
        headers: {
          'Authorization': 'Basic ' + localStorage.getItem(API_TOKEN)
        },
        responseType: 'blob',
      }

      const response = await axios(config);

      const urlDownload = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlDownload;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  }

  post = async (url, data) => {
    try {
      var config = {
        method: 'post',
        url: this.baseUrl + url,
        headers: {
          'Authorization': 'Basic ' + localStorage.getItem(API_TOKEN),
          'Content-Type': 'application/json'
        },
        data: data
      };
      return await axios(config)
    } catch (err) {
      if (err.response?.data.message) {
        notification["error"]({
          message: err.response?.data.message,
        });
      }
    }
  }

  put = async (url, data) => {
    try {
      var config = {
        method: 'put',
        url: this.baseUrl + url,
        headers: {
          'Authorization': 'Basic ' + localStorage.getItem(API_TOKEN),
          'Content-Type': 'application/json'
        },
        data: data
      };
      return await axios(config)
    } catch (err) {
      if (err.response?.data.message) {
        notification["error"]({
          message: err.response?.data.message,
        });
      }
    }
  }

  patch = async (url, data) => {
    try {
      var config = {
        method: 'patch',
        url: this.baseUrl + url,
        headers: {
          'Authorization': 'Basic ' + localStorage.getItem(API_TOKEN),
          'Content-Type': 'application/json'
        },
        data: data
      };
      return await axios(config)
    } catch (err) {
      if (err.response?.data.message) {
        notification["error"]({
          message: err.response?.data.message,
        });
      }
    }
  }

  del = async (url, id) => {
    try {
      var config = {
        method: 'delete',
        url: this.baseUrl + url,
        headers: {
          'Authorization': 'Basic ' + localStorage.getItem(API_TOKEN),
          'Content-Type': 'application/json'
        }
      };
      return await axios(config)
    } catch (err) {
      if (err.response?.data.message) {
        notification["error"]({
          message: err.response?.data.message,
        });
      }
    }
  }

}

// eslint-disable-next-line 
export default new Service(URL);
