import React from 'react';
import { notification, Input, Button, Space, Tag } from 'antd';
import { SearchOutlined, FormOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import "./RolesList.scss";
import service from '../../services/Service';
import { Link } from "react-router-dom";
import { withLocale } from '../../l10n';
import DataTable from '../DataTable/DataTable';
import DeleteButton from '../DeleteButton/DeleteButton';

class RolesList extends React.Component {
  state = {
    data: [],
    botonDisabled: true,
    loading: false,
    update: false,
    lang: this.props.lang
  };

  getColumnSearchProps = (dataIndex, lang) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {lang.handle('Buscar')}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            {lang.handle('Borrar')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  componentDidMount() {
    this.fetch();
  }
  componentDidUpdate() {
    if (this.state.update) {
      this.setState({ update: false });
      this.fetch();
    }
  }

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    service.get('roles')
      .then(res => {
        let temp_data = [];
        if (res.data.ok === false) {
          notification["error"]({
            message: this.props.lang.handle(res.data.message),
          });
          temp_data = [];
        } else {
          temp_data = res.data;
        }

        this.setState({
          loading: false,
          data: temp_data,
        });
      });
  };

  deleteRole = async () => {
    const { lang } = this.state;
    this.setState({ loading: true });
    await service.del('roles/' + this.state.selectedUuid, this.state.selectedUuid)
      .then(res => {
        if (res.data.ok === false) {
          notification["error"]({
            message: lang.handle(res.data.message),
          });
        } else {
          notification["success"]({
            message: lang.handle('Rol borrado correctamente'),
          });
        }
        this.fetch()
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log('Error devuelto: ' + err);
      })
  };

  updateRole = async () => {
    this.setState({ loading: true });
    window.location.href = '/users/roles/config/' + this.state.selectedUuid;
  }

  render() {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          botonDisabled: false,
          selectedUuid: selectedRows[0].uuid,
        });
      },
    };

    const { lang } = this.state;

    const columns = [
      {
        title: lang.handle('Rol'),
        dataIndex: 'name',
        ...this.getColumnSearchProps('name', lang),
      },
      {
        title: lang.handle('Administrador'),
        dataIndex: 'admin',
        render: (value) => <Tag className='admin'>{value ? lang.handle('Rol administrador') : ""}</Tag>,
      },
    ];
    const { data, loading } = this.state;
    return (
      <div>
        <Button
          className='new'
          type="primary"
        >
          <Link to={"/users/roles/config"} style={{ textDecoration: 'none', color: 'white' }}>
            {lang.handle('Nuevo Rol')}
          </Link>
        </Button>
        <DataTable
          rowSelection={{
            type: "radio",
            ...rowSelection
          }}
          columns={columns}
          dataSource={data}
          rowKey="uuid"
          loading={loading}
        />
        <div className="button-panel">
          <Space>
            <Button
              type="primary"
              icon={<FormOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.updateRole}
            >
              {lang.handle('Editar')}
            </Button>

            <DeleteButton
              onEvent={this.deleteRole}
              buttonDisabled={this.state.botonDisabled}
            />

          </Space>
        </div>
      </div>
    );
  }
}

export default withLocale(RolesList);
