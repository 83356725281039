import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { getTokenInfo, hasPrivilege } from "../../api/token";
import PoolTable from "./components/PoolTable";

export default function PoolList() {
  const navigate = useNavigate();
  const pools_view = hasPrivilege('pools_view');

  useEffect(() => {
    if (!pools_view) {
      navigate("/");
    }
  }, [navigate, pools_view])

  const tenant_uuid = getTokenInfo().tenant_uuid;

  if (pools_view) {
    return (
      <div>
         <PoolTable
           tenant={tenant_uuid}
         />
      </div>
    )
  }
}
