import React, { Component } from 'react';
import { Modal, Button, notification } from "antd";
import { ReloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import service from '../../services/Service';
import { withLocale } from '../../l10n';
class SyncModal extends Component {
  state = {
    showModal: false,
    confirmLoading: false,
    devices: {
      uuid: [],
      names: [],
      tenant_uuid: [],
      subscriptions: [],
      plans_names: [],
    },
    hiddenButton: true,
    tableLoading: false,
    lang: this.props.lang
  }

  showModal = () => {
    this.setState({ showModal: true })
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  onSubmit = async (e) => {
    this.setState({ confirmLoading: true });
    await service.put('devices')
      .then(res => {
        this.setState({
          showModal: false,
          confirmLoading: false,
        });
        notification["success"]({
          message: this.props.lang.handle("Actualizado con exito"),
        });
      });
  }

  render() {
    const { lang } = this.state;
    return (
      <div>
        <Button
          type="primary"
          icon={<ReloadOutlined />}
          onClick={this.showModal}
        >
          {lang.handle('Sincronizar')}
        </Button>
        <Modal
          title={lang.handle('¡Sincronizar datos!')}
          onCancel={this.handleCancel}
          cancelText={lang.handle('Cerrar')}
          onOk={this.onSubmit}
          okText={lang.handle('Sincronizar')}
          icon={<ExclamationCircleOutlined />}
          open={this.state.showModal}
          confirmLoading={this.state.confirmLoading}
        >
          {lang.handle('Nombre, Organización y subscripción van a ser modificados por el valor que tengan en la plataforma Kiconex ¿Está seguro de esto?')}
          
        </Modal>
      </div>
    );
  }
}

export default withLocale(SyncModal);
