import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import DevicesList from "../components/DevicesList";

export default function Devices() {
  const navigate = useNavigate();
  const mykiconex_view = hasPrivilege('mykiconex_view');

  useEffect(() => {
    if (!mykiconex_view) {
      navigate("/");
    }
  }, [navigate, mykiconex_view])

  if (mykiconex_view) {
    return (
      <div>
        <DevicesList />
      </div>
    )
  }
}
