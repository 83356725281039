import { useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { hasPrivilege } from "../api/token";


export default function VPN_OLD() {
    const navigate = useNavigate();

    useEffect(() => {
      if (!hasPrivilege('vpn_view_old')) {
        navigate("/");
      }

      const URL_VPN = process.env.REACT_APP_VPN_URL;
      window.open(URL_VPN, "_blank");
      navigate("/");

    }, [navigate])
}
