import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import { Tabs } from "antd";
import KiboxAssignedList from "../components/KiboxAssignedList";
import KiwiAssignedList from "../components/KiwiAssignedList";
import { withLocale } from './../l10n';
function AssignedList(props) {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (!hasPrivilege('assign_view')) {
      navigate("/");
    }
  }, [navigate])

  const items = [
    { label: props.lang.handle('Kibox asignados'), key: '1', children: <KiboxAssignedList /> },
    { label: props.lang.handle('Kiwis asignados'), key: '2', children: <KiwiAssignedList /> },
  ]

  if (hasPrivilege('assign_view')) {
    return (<Tabs
      type="card"
      activeKey={params.tab}
      items={items}
      onChange={key => {
        navigate(`/boards/manufacture/assigned/${key}`)
      }} />)
  }
}

export default withLocale(AssignedList);
