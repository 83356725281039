import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import BoardsInfoTable from '../components/BoardsInfoTable';

export default function BoardsInfo() {
  const navigate = useNavigate();
  const info_view = hasPrivilege('info_view');

  useEffect(() => {
    if (!info_view) {
      navigate("/");
    }
  }, [navigate, info_view])

  if (info_view) {
    return (
      <div>
        <BoardsInfoTable />
      </div>
    )
  }
}
