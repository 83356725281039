import React from "react";
import { Button } from "antd";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

const ExportExcel = ({ fileName, fileData, label }) => {
  const ESPECIAL_FORMATS = ['renewal_diff'];
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  let disabled = false;
  fileData.length === 0 ? disabled = true : disabled = false;

  function parse(format, type, value) {
    switch (format) {
      case 'renewal_diff':
        return parseRenewalDiff(type, value);
      default:
        return { t: type, v: value };
    }
  }

  function parseRenewalDiff(type, value) {
    if (value === 0) {
      value = 'Expired';
      type = 's';
    } else {
      value = Math.floor(value);
    }

    return { t: type, v: value };
  }

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(fileData);
    const valores = parseInt(ws['!ref'].slice(4, 5)) + 1;

    Object.entries(ws).forEach(([key, data]) => {
      if (/[A-Z]+1$/.test(key)) {

        //Añadir a la constante, el nombre de la columna que se desea formatear cada vez que se agrege un formateo nuevo.
        if (ESPECIAL_FORMATS.includes(data.v)) {
          for (let i = 2; i < valores; i++) {
            let clave = key.slice(0, 1) + i;
            ws[clave] = parse(data.v, ws[clave].t, ws[clave].v)
          }
        }

        data.s = {
          fill: {
            bgColor: {
              rgb: "252527"
            },
            fgColor: {
              rgb: "252527"
            }
          },
          font: {
            bold: true,
            color: {
              rgb: "FFFFFF"
            },
          },
        }
      }
    });

    const wb = { Sheets: { 'renewel': ws }, SheetNames: ['renewel'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { file: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  return (
    <>
      <Button
        variant="contained"
        disabled={disabled}
        onClick={(e) => exportToExcel()}>
        {label}
      </Button>
    </>
  )
}

export default ExportExcel;
