import React from 'react';
import { useEffect } from 'react';
import Renewals from "../components/Renewals/Renewals";
import { withLocale } from "../l10n";
import { hasPrivilege } from "../api/token";
import { useNavigate, useLocation } from "react-router-dom";

function Renewal() {
  const location = useLocation();  
  const navigate = useNavigate();
  const administration_renewal = hasPrivilege('administration_renewal');

  useEffect(() => {
    if (!administration_renewal) {
      navigate("/");
    }
  }, [navigate, administration_renewal]);

  if (administration_renewal) {
    return (
      <div>
        <Renewals location={location}/>
      </div>
    )
  }
}

export default withLocale(Renewal);
