import React, { Component } from "react";
import "./KiwiCard.scss";
import { Select, Divider, notification, DatePicker } from "antd";
import {
  ssidValidation,
  uuidValidation,
  macValidation,
  lengthValidation,
  typeValidation
} from "../../utils/formValidation";
import { getBoardsByPED } from "../../api/getBoardsByPED";
import service from "../../services/Service";
import moment from "moment";
import dayjs from 'dayjs';
import { withLocale } from "../../l10n";
import Loading from "../../utils/loading";

const { Option } = Select;

let index = 0;
//var boards;
//const listUrl ='';

class KiwiCard extends Component {
  // Preinicializamos el estado de la clase
  state = {
    uuid: "",
    order_id: "",
    serial_number: "",
    work_order: "",
    ssid: "",
    mac: "",
    board_uuid: "",
    date: new Date(),
    software: "v1.3.0",
    notes: "",
    state: "Asignado",
    errors: {
      ssid: "",
      mac: "",
      serial_number: "",
      work_order: "",
      board_uuid: "",
    },
    items: [],
    itemName: "",
    lang: this.props.lang,
    loading: false
  };

  onNameChange = (event) => {
    this.setState({
      itemName: event.target.value,
    });
  };

  addItem = () => {
    const { items, itemName } = this.state;
    this.setState({
      items: [...items, itemName || `New item ${index++}`],
      itemName: "",
    });
  };

  // Funcion que guarda el valor introducido en el formulario dentro del campo correspondiente de state
  onInputChange = (e) => {
    const { name, value } = e.target;
    let { items, errors, lang } = this.state;
    switch (name) {
      case "ssid":
        errors.ssid = ssidValidation(value) ? "" : lang.handle('¡SSID erróneo!');
        break;
      case "mac":
        errors.mac = macValidation(value) ? "" : lang.handle('¡MAC errónea!');
        break;
      case "serial_number":
        errors.serial_number = lengthValidation(value, 14)
          ? ""
          : lang.handle('¡Número de serie erróneo!');
        break;
      case "work_order":
        errors.work_order = lengthValidation(value, 8) && typeValidation(value, 'number') ? '' : lang.handle('¡Orden de trabajo erronea!');
        break;
      case "board_uuid":
        errors.board_uuid = uuidValidation(value) ? "" : lang.handle('¡UUID erróneo!');
        break;
      case "order_id":
        let boards = getBoardsByPED(value);
        getBoardsByPED(value).then((result) => {
          boards = result;
          items = [];
          this.setState({
            items: Object.assign(items, boards),
          });
        });
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };

  // Funcion que guarda el valor escogido en el calendario dentro del campo correspondiente de state
  onChangeDate = (date, dateString) => {
    this.setState({ date: dateString });
  };

  onSaveData = () => {
    const urlListaKiwi = "/boards/list/2";
    const urlKiwisManufactured = "/boards/manufacture/list/2";
    const urlKiwisAssigned = "/boards/manufacture/assigned/2";
    const state = this.state.state;

    switch (state) {
      case "Fabricado":
        window.location.href = urlKiwisManufactured;
        break;
      case "Asignado":
        window.location.href = urlKiwisAssigned;
        break;
      case "Terminado":
        window.location.href = urlListaKiwi;
        break;
      default:
        break;
    }
  };

  // Funcion que envia la informacion a la base de datos. Si es una placa nueva la crea, si es una antigua la actualiza
  onSubmit = async (e) => {
    e.preventDefault();
    const boardData = this.state;
    const { errors, lang } = this.state;

    if (boardData.date instanceof Date) {
      boardData.date = moment(this.state.date).format("DD-MM-YYYY");
    }

    if (!errors.ssid && !errors.mac && !errors.serial_number && !errors.work_order && !errors.board_uuid) {
      if (this.props.newBoard) {
        await service
          .post("kiwi", boardData)
          .then((res) => {
            if (res.data.ok === false) {
              notification["error"]({
                message: lang.handle(res.data.message),
              });
              return;
            } else {
              notification["success"]({
                message: lang.handle('Placa creada correctamente'),
              });
              this.onSaveData();
            }
          })
          .catch((err) => {
            console.log("Error devuelto: " + err);
          });
      } else {
        await service
          .put("kiwi/" + this.state.uuid, boardData)
          .then((res) => {
            if (res.data.ok === false) {
              notification["error"]({
                message: lang.handle(res.data.message),
              });
              return;
            } else {
              notification["success"]({
                message: lang.handle('Placa actualizada correctamente'),
              });
              this.onSaveData();
            }
          })
          .catch((err) => {
            console.log("Error devuelto: " + err);
          });
      }
    } else {
      notification["error"]({
        message: lang.handle('Rellene el formulario correctamente'),
      });
    }
  };

  // Funcion que se ejecuta cuando el componente se ha montado
  async componentDidMount() {
    // Si es una placa cargada desde el listado de placas, se obtiene mediante su id
    if (this.props.ident) {
      this.setState({ loading: true })
      await service
        .get("kiwi/" + this.props.ident)
        .then((res) => {
          if (res.data.ok === false) {
            notification["error"]({
              message: this.props.lang.handle(res.data.message),
            });
            return;
          }
          const data = res.data[0];
          this.setState({
            uuid: data.uuid,
            order_id: data.order_id,
            serial_number: data.serial_number,
            work_order: data.work_order,
            ssid: data.ssid,
            mac: data.mac,
            board_uuid: data.board_uuid,
            date: new Date(data.date),
            software: data.software,
            state: this.props.location.pathname.includes("assing") ? "Asignado" : data.state,
            notes: data.notes,
            editing: true,
            loading: false
          });
        })
        .catch((err) => {
          console.log("Error devuelto: " + err);
        });
    }
  }

  render() {
    // Variable que lleva el titulo de la tarjeta. Se pasa un valor en funcion de si es un elemento nuevo o uno antiguo
    var title = "";
    const { lang } = this.state;
    // Se evalua la propiedad "newBoard" del componente. En funcion de esta, se muestra un titulo u otro en la tarjeta
    if (this.props.newBoard === true) {
      title = lang.handle('Nuevo KiWi');
    } else {
      title = lang.handle('Información de kiwi') + " " + this.state.ssid;
    }

    // Funcion que crea el boton de guardado
    function Save({ save }) {
      if (save === true) {
        return (
          /* Boton del formulario */
          <button
            id="button-save"
            className="btn btn-primary d-flex float-right"
            type="submit"
          >
            <div className="btnText">
              <p> {lang.handle('Guardar')}&nbsp;&nbsp; </p>
              <i className="material-icons"> save </i>
            </div>
          </button>
        );
      } else {
        return false;
      }
    }

    const { items, errors, loading } = this.state;

    return (
      <div className="card">
        {loading && <Loading loading={loading} />}
        {/* Titulo de la tarjeta */}
        <h4 className="card-title p-3"> {title} </h4>

        {/* Formulario con los datos a cumplimentar */}
        <form onSubmit={this.onSubmit} className="p-3">
          {/* Numero de pedido */}
          <div className="form-group row">
            <label
              htmlFor="order_id"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              {lang.handle('Pedido')}{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="order_id"
                placeholder="20PED4596"
                onChange={this.onInputChange}
                name="order_id"
                value={this.state.order_id}
                disabled={!this.props.editable}
                required
              />
            </div>
          </div>

          {/* Nº serie */}
          <div className="form-group row">
            <label
              htmlFor="serial_number"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              {lang.handle('N serie')}{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="serial_number"
                placeholder="202005080005"
                onChange={this.onInputChange}
                name="serial_number"
                value={this.state.serial_number}
                disabled={!this.props.editable}
              />
              {errors.serial_number.length > 0 && (
                <span className="boardFormError">{errors.serial_number}</span>
              )}
            </div>
          </div>

          {/* Orden de trabajo */}
          <div className="form-group row">
            <label
              htmlFor="work_order"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {lang.handle('Orden de trabajo')}
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="work_order"
                onChange={this.onInputChange}
                name="work_order"
                value={this.state.work_order}
                disabled={!this.props.editable}
              />
              {errors.work_order.length > 0 && (
                <span className="boardFormError">{errors.work_order}</span>
              )}
            </div>
          </div>

          {/* SSID placa */}
          <div className="form-group row">
            <label
              htmlFor="ssid"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              SSID{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="ssid"
                placeholder="KIWI_54A6BC"
                onChange={this.onInputChange}
                name="ssid"
                value={this.state.ssid}
                disabled={!this.props.editable}
                required
              />
              {errors.ssid.length > 0 && (
                <span className="boardFormError">{errors.ssid}</span>
              )}
            </div>
          </div>

          {/* MAC */}
          <div className="form-group row">
            <label
              htmlFor="mac"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              MAC{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="mac"
                placeholder="30:A4:F5:20:4C:5A"
                onChange={this.onInputChange}
                name="mac"
                value={this.state.mac}
                disabled={!this.props.editable}
              />
              {errors.mac.length > 0 && (
                <span className="boardFormError">{errors.mac}</span>
              )}
            </div>
          </div>

          {/* UUID placa */}
          <div className="form-group row">
            <div className="col newCard-form-text"> UUID </div>
            <div className="col-sm-10">
              <Select
                showSearch
                className="kiwi-form__select"
                placeholder="37555f8a-1cab-486b-ab2e-1c65e80b2c12"
                optionFilterProp="board_uuid"
                value={this.state.board_uuid}
                // eslint-disable-next-line
                onSelect={(value, event) => this.setState({ ['board_uuid']: value })}
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) => {
                  if (optionA.items) {
                    optionA.items
                      .toLowerCase()
                      .localeCompare(optionB.items.toLowerCase());
                  }
                }}
                name="board_uuid"
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    {/* <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
										<Input style={{ flex: 'auto' }} value={itemName} onChange={this.onNameChange} />
										<a
										  style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
										  onClick={this.addItem}
										>
										  <PlusOutlined /> Add item
										</a>
									  </div> */}
                  </div>
                )}
              >
                {items.map((item) => (
                  <Option type="uuid" key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          {/* Fecha Placa */}
          <div className="form-group row">
            <div className="col newCard-form-text"> {lang.handle('Fecha fabricación')} </div>
            <div className="col-sm-10">
              <DatePicker
                className="form-control"
                value={dayjs(moment(this.state.date, "DD-MM-YYYY"))}
                onChange={this.onChangeDate}
                format="DD-MM-YYYY"
                disabled={!this.props.editable}
                required
              />
            </div>
          </div>

          {/* Versión de software */}
          <div className="form-group row">
            <label
              htmlFor="software"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              {lang.handle('Software')}{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="software"
                placeholder="vX.X"
                onChange={this.onInputChange}
                name="software"
                value={this.state.software}
                required
              />
            </div>
          </div>

          {/* Notas */}
          <div className="form-group row">
            <label
              htmlFor="notes"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              {lang.handle('Notas')}{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="notes"
                placeholder={lang.handle('Este pedido debe salir con una pegatina extra')}
                onChange={this.onInputChange}
                name="notes"
                value={this.state.notes}
                disabled={!this.props.editable}
              />
            </div>
          </div>

          {/* Estado de fabricación */}
          <div className="form-group row">
            <div className="col newCard-form-text"> {lang.handle('Estado de fabricación')} </div>
            <div className="col-sm-10">
              <select
                className="form-control"
                onChange={this.onInputChange}
                name="state"
                value={this.state.state}
                disabled={!this.props.editable}
                required
              >
                <option value="Fabricado"> {lang.handle('Fabricado')} </option>
                <option value="Asignado" defaultValue>
                  {" "}
                  {lang.handle('Asignado')}{" "}
                </option>
                <option value="Terminado"> {lang.handle('Terminado')} </option>
                <option value="Eliminado"> {lang.handle('Eliminado')} </option>
              </select>
            </div>
          </div>

          {/* Boton del formulario */}
          {/* 
					<Link to={this.props.roleUrl+"/listaplacas/2"}>
						<button id='button-back'  className = "btn btn-primary d-flex float-left" type = 'button'>
							<div className = "btnText">
								<p> Volver&nbsp;&nbsp; </p>
								<i className = "material-icons"> keyboard_backspace </i>
							</div>
						</button>			
					</Link>*/}

          <Save save={this.props.editable} />
        </form>
      </div>
    );
  }
}

export default withLocale(KiwiCard);
