import { Component } from "react";
import { Button, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { withLocale } from "../../l10n";

/**
 * Componente DeleteButton
 * 
 * Este componente renderiza un botón que, al hacer clic, muestra un modal de confirmación
 * para eliminar un elemento. Si el usuario confirma, se ejecuta una función proporcionada
 * por el componente padre para manejar la eliminación.
 * 
 * Props:
 * - onEvent: Función que se ejecuta al confirmar la eliminación.
 * - buttonDisabled: Propiedad booleana que determina si el botón de eliminar está deshabilitado.
 */
class DeleteButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisibility: false,
    };
  }

  /**
   * Muestra el modal de confirmación.
   */
  showModal = () => {
    this.setState({ isModalVisibility: true });
  };

  /**
   * Procesa la confirmación de eliminación.
   * Ejecuta la función onEvent y cierra el modal.
   * Llama al callback pasado desde el componente padre si existe
   */
  confirmDelete = () => {
    const { onEvent } = this.props;
    if (onEvent) onEvent();
    this.setState({ isModalVisibility: false });
  };

  /**
   * Cancela la eliminación y cierra el modal.
   */
  cancelDelete = () => {
    this.setState({ isModalVisibility: false });
  };

  render() {
    const { buttonDisabled, lang } = this.props;
    const { isModalVisibility } = this.state;

    return (
      <>
        <Button
          type="primary"
          onClick={this.showModal}
          icon={<DeleteOutlined />}
          disabled={buttonDisabled}
        >
          {lang.handle("Eliminar")}
        </Button>

        <Modal
          title={lang.handle("Confirmación de eliminación")}
          visible={isModalVisibility}
          onOk={this.confirmDelete}
          onCancel={this.cancelDelete}
          okText={lang.handle("Sí, eliminar")}
          cancelText={lang.handle("Cancelar")}
        >
          <p>{lang.handle("¿Está seguro de que desea eliminar este elemento?")}</p>
        </Modal>
      </>
    );
  }
}

export default withLocale(DeleteButton); 
