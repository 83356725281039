import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { notification, Input, Button, Space, Tag, Modal, Form, Select, Checkbox, Divider, Alert, DatePicker, Switch } from 'antd';
import { SearchOutlined, MobileOutlined, ExclamationCircleOutlined, CloseOutlined, CheckOutlined, FilterFilled, AlertOutlined, WarningOutlined } from '@ant-design/icons';
import { getTokenInfo, hasPrivilege } from "../../api/token";
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import service from '../../services/Service';
import { withLocale } from '../../l10n';
import "./m2mList.scss";
import DataTable from '../DataTable/DataTable';
import BarChart from '../BarChart/BarChart';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

class M2MList extends Component {

  M2M_ALARMS_STATE = [
    {
      label: this.props.lang.handle("Sin alertas"), 
      value: 0
    },
    {
      label: this.props.lang.handle("Advertencia"), 
      value: 1
    },
    {
      label: this.props.lang.handle("Critica"), 
      value: 2
    }
  ];

  M2M_ALARMS_TAG = [
    {
      label: <div key="warning"><Tag color='yellow' className='m-2' icon={<WarningOutlined/>}>{this.props.lang.handle('Advertencia')}</Tag></div>, 
      value: 1
    },
    {
      label: <div key="critical"><Tag color='red' className='m-2' icon={<AlertOutlined/>}>{this.props.lang.handle('Critica')}</Tag></div>, 
      value: 2
    },
  ]

  state = {
    datos: [],
    iccAlerts: [],
    alertDescription: "",
    loading: false,
    botonDisabled: true,
    selectedId: '',
    showModal: false,
    showModalState: false,
    showModalConsume: false,
    lifeCycle: '',
    stateData: {
      responseGSM: '',
      responseGPRS: ''
    },
    featuresGSM: {
      backgroun: 'red',
      text: 'Error',
      icon: '',
    },
    featuresGPRS: {
      background: 'red',
      text: 'Error',
      icon: '',
    },
    tenants: [],
    lang: this.props.lang,
    searchedColumns: this.props.location.state && this.props.location.state.filter === true ? [1,2] : [],
    searchedIcc: this.props.location && this.props.location.id ? [this.props.location.id] : [],
    m2mAlerts: this.M2M_ALARMS_TAG,
    dates: null,
    consumes: null
  };

  m2m_consmption_params = {
    start_date: '',
    end_date: '',
    monthly: true,
  };

  // Funcion que muestra los modales dependiendo del id
  showModal = async (e) => {
    if (e.currentTarget.id === "lifeCycle") {
      this.setState({ showModal: true });
    } else if (e.currentTarget.id === "state"){
      const icc = this.state.selectedId;
      this.setState({ loading: true });
      await service.get('m2m/status/' + icc)
        .then(res => {
          if (res.data.ok === false) {
            notification["error"]({
              message: this.props.lang.handle(res.data.message),
            });
            this.setState({ loading: false })
            return;
          }

          this.setState({ loading: false, stateData: res.data })
          if (res.data.responseGSM.result === 'GSM_UP') {
            const newfeatures = { background: "green", text: "OK", icon: <CheckOutlined /> };
            this.setState({ featuresGSM: newfeatures });
          } else {
            const newfeatures = { background: "red", text: this.props.lang.handle('Error'), icon: <CloseOutlined /> };
            this.setState({ featuresGSM: newfeatures });
          }

          if (res.data.responseGPRS.result === 'GPRS_UP') {
            const newfeatures = { background: "green", text: "OK", icon: <CheckOutlined /> };
            this.setState({ featuresGPRS: newfeatures });
          } else {
            const newfeatures = { background: "red", text: this.props.lang.handle('Error'), icon: <CloseOutlined /> };
            this.setState({ featuresGPRS: newfeatures });
          }
          this.setState({ showModalState: true });
        })
      } else if(e.currentTarget.id === "consume"){
        this.m2m_consmption_params.end_date = this.getCurrentDate();
        this.m2m_consmption_params.start_date = this.getDateThreeMonthsAgo();
        this.m2m_consmption_params.monthly = true;
        this.setState({ showModalConsume: true });
        this.showGrafic();
    }
  };

  // Funcion que cierra el modal al pulsar cancel
  handleCancel = () => {
    this.setState({ lifeCycle: '' });
    this.setState({ showModal: false, showModalState: false, showModalConsume: false });
  };

  handleChange = (value) => {
    this.setState({ lifeCycle: value });
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const simData = { lifeCycleStatus: this.state.lifeCycle, icc: this.state.selectedId };
    await service.put('m2m/' + simData.icc, simData)
      .then(res => {
        if (res.data.ok === false) {
          notification["error"]({
            message: this.props.lang.handle(res.data.message),
          });
          this.setState({ loading: false })
          return;
        }

        window.location.reload();
      })
  }

  search = (selectedKeys, confirm) => {
    confirm();
    this.setState({
      searchedColumns: selectedKeys,
      searchedIcc: selectedKeys,
    });
  };

  reset = (clearFilters) => {
    clearFilters();
    this.setState({
      searchedColumns: [],
      searchedIcc: [],
    });
  };

  getColumnFilterProps = () => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys = this.props.location.state ? [0] : [],
      confirm,
      clearFilters,
    }) => (
      <div className="d-flex flex-column" id="checkbox">
        <Checkbox.Group
          options={this.M2M_ALARMS_STATE}
          value={selectedKeys}
          onChange={(values) => setSelectedKeys(values)}
          className="flex-column mx-2 px-2 pb-1 pt-2"
        />
        <Divider className="my-0"/>
        <Space className="p-2">
        <Button
            onClick={() => this.reset(clearFilters)}
            size="small"
            className="w-100"
            disabled={selectedKeys.length > 0 ? false : true}
          >
            {this.props.lang.handle('Reiniciar')}
          </Button>
          <Button
            type="primary"
            onClick={() => this.search(selectedKeys, confirm)}
            size="small"
            className="w-100"
          >
            {this.props.lang.handle('Aceptar')}
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value, record) => {
      let filter = false;

      record.alarms.forEach(alarm => {
        if (alarm === value) {
          filter = true;
        }
      })

      return filter;
    },
    defaultFilteredValue: this.state.searchedColumns
  });

  getColumnSearchProps = (dataIndex)=> ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.lang.handle('Search')}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {this.props.lang.handle('Reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
    defaultFilteredValue: dataIndex === 'icc' ? this.state.searchedIcc : null
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  componentDidMount() {
    this.Pfetch({});
  }

  info = (icc) => {
    return <Link className='iccLabel' key={icc} to={"/redirect/m2m/" + icc} style={{ textDecoration: 'none' }}><span className="nav-text"> {icc} </span></Link>
  }

  openInfo = () => {
    let info = [];
    this.state.iccAlerts.forEach(e => {
      info.push(this.info(e.icc));
    });
    this.setState({ alertDescription: info });
  }

  Pfetch = async () => {
    const tenant_uuid = getTokenInfo().tenant_uuid;
    const params = {
      tenant_uuid: tenant_uuid,
    }

    this.setState({ loading: true });
    await service.get('tenants')
      .then(res => {
        this.setState({
          tenants: res.data, 
          select_loading: false,
        })
      });
    await service.get('m2m', params)
      .then(res => {
        if (res.data.ok === false) {
          notification["error"]({
            message: this.props.lang.handle(res.data.message),
          });
          this.setState({
            datos: [],
          });
        } else {
          this.setState({
            datos: res.data,
          });
        }
      })
      .catch(err => {
        console.log('Error devuelto:' + err);
      })
      await service.get('m2m/deleted-risk', params)
      .then(res => {
        if (res.data.ok === false) {
          notification["error"]({
            message: this.props.lang.handle(res.data.message),
          });
          this.setState({
            iccAlerts: [],
          });
        } else {
          this.setState({
            iccAlerts: res.data,
          });
        }
      })
      .catch(err => {
        console.log('Error devuelto:' + err);
      })
      this.setState({
        loading: false,
      });
  };

  showGrafic = async (e) => {
    await service.post(`m2m/${this.state.selectedId}/consumes`, this.m2m_consmption_params)
      .then(res => {
        if (res.data.ok === false) {
          console.log("fail");
        } else {
          const jsonData = res.data;

          const dates = jsonData.map(data => data.date);
          const consumes = jsonData.map(data => data.data);

          //Al actualizar los props el componente recargara solo
          this.setState({dates : dates, consumes : consumes});
        }
      })
  };

  onMontlyChange = (e) => {
    this.forceUpdate();
  };

  hasMoreThanOneMonthDifference = (date1, date2) => {
    const [year1, month1, day1] = date1.split('-').map(Number);
    const [year2, month2, day2] = date2.split('-').map(Number);

    // Convertir las fechas a milisegundos
    const startMillis = new Date(year1, month1 - 1, day1).getTime();
    const endMillis = new Date(year2, month2 - 1, day2).getTime();

    // Calcular la diferencia en milisegundos
    const diffMillis = Math.abs(endMillis - startMillis);

    // Calcular el último día del mes de la fecha de inicio
    const lastDayOfMonthStart = new Date(year1, month1, 0).getDate();

    // Calcular el número total de días en el mes de la fecha de inicio
    const daysInMonthStart = lastDayOfMonthStart - day1 + 1;

    return diffMillis > daysInMonthStart * 24 * 60 * 60 * 1000;
}


  handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      this.m2m_consmption_params.start_date = dates[0].format('YYYY-MM-DD');
      this.m2m_consmption_params.end_date = dates[1].format('YYYY-MM-DD');
      this.m2m_consmption_params.monthly = !this.hasMoreThanOneMonthDifference(this.m2m_consmption_params.start_date,this.m2m_consmption_params.end_date) ? false : true;
      this.onMontlyChange();
      this.showGrafic();
    }
  };

  getCurrentDate = () =>{
    const today = new Date();

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    const formatted_date = `${year}-${month}-${day}`;

    return formatted_date;
  }

  getDateThreeMonthsAgo = () =>{
    const today = new Date();
    const three_months_ago = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());

    const year = three_months_ago.getFullYear();
    const month = String(three_months_ago.getMonth() + 1).padStart(2, '0');
    const day = String(three_months_ago.getDate()).padStart(2, '0');

    const formatted_date = `${year}-${month}-${day}`;

    return formatted_date;
  }

  render() {
    var filter = [];
    if (this.state.tenants.length > 0) {
      this.state.tenants.forEach(tenant => {
        filter.push({ text: tenant.name.toUpperCase(), value: tenant.name.toUpperCase() })
      });

    }

    const rowSelection = {
      onChange: (selectedRows) => {
        this.setState({
          botonDisabled: false,
          selectedId: selectedRows[0],
        })
      },
      getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };
    const { lang, m2mAlerts } = this.state;
    const columns = [

      /* [ alias, commercialGroup ¿?, consumptionDaily.data.value, consumptionMonthly.data.value, customField1, customField1, gprsStatus.lastConnStop, 
        gprsStatus.lastConnStart, icc, imei, lifeCycleStatus, presence.sgsn.operator.company, presence.sgsn.operator.countryCode, ratType, servicePack ] */
      {
        title: lang.handle('Instalación/Pedido'),
        dataIndex: 'alias',
        sorter: (a, b) => a.alias.length - b.alias.length,
        ...this.getColumnSearchProps('alias')
      },
      {
        title: lang.handle('Organización'),
        dataIndex: 'customField1',
        filters: filter,
        onFilter: (value, record) => record.customField1 && record.customField1.toLowerCase() === value.toLowerCase(),
      },
      {
        title: 'ICC',
        dataIndex: 'icc',
        key: '_id',
        sorter: (a, b) => a.icc - b.icc,
        ...this.getColumnSearchProps('icc')
      },
      {
        title: 'MSISDN',
        dataIndex: 'msisdn',
        sorter: (a, b) => a.msisdn - b.msisdn,
        ...this.getColumnSearchProps('msisdn')
      },
      {
        title: lang.handle('Estado'),
        dataIndex: 'lifeCycleStatus',
        filters: [
          { text: lang.handle('Desactivada'), value: 'DEACTIVATED' },
          { text: lang.handle('Activada'), value: 'ACTIVE' },
          { text: lang.handle('Inactiva nueva'), value: 'INACTIVE_NEW' },
          { text: lang.handle('Lista para activación'), value: 'ACTIVATION_READY' }
        ],
        onFilter: (value, record) => record.lifeCycleStatus && record.lifeCycleStatus.toLowerCase() === value.toLowerCase(),
        render: function (data) {
          switch (data) {
            case "DEACTIVATED":
              return <Tag color='red'> {lang.handle('Desactivada')} </Tag>;

            case "ACTIVE":
              return <Tag color='green'> {lang.handle('Activada')} </Tag>;

            case "INACTIVE_NEW":
              return <Tag color='gray'> {lang.handle('Inactiva nueva')} </Tag>;

            case "ACTIVATION_READY":
              return <Tag color='orange'> {lang.handle('Lista para activación')} </Tag>;

            default:
              return <Tag color='Black'> {lang.handle('Desconocido')} </Tag>;
          }
        },
      },
      {
        title: lang.handle('Alarmas'),
        dataIndex: "alarms",
        ...this.getColumnFilterProps(),
        filterIcon: <FilterFilled />,
        render: function (data) {
          let tags = [];

          data.forEach(value => {
            if (m2mAlerts.find(tag => tag.value === value) !== undefined) {
              tags.push(m2mAlerts.find(tag => tag.value === value).label);
            }
          })

          return tags;
        },
      },
      {
        title: lang.handle('Consumo diario'),
        dataIndex: ['consumptionDaily', 'data', 'value'],
        sorter: (a, b) => a.consumptionDaily.data.value - b.consumptionDaily.data.value,
        render: function (data) {
          var tag = "";
          if (data < 1024) {
            tag = "bytes";
          }
          else if (data < 1024*1024) {
            tag = "KB";
            data = (data / 1024).toFixed(2);
          }
          else if (data < 1024*1024*1024) {
            tag = "MB";
            data = ((data / 1024) / 1024).toFixed(2);
          }
          else {
            tag = "GB";
            data = (((data / 1024) / 1024) / 1024).toFixed(2);
          }
          return (
            <p> {data} {tag} </p>
          );
        }
      },
      {
        title: lang.handle('Consumo mensual'),
        dataIndex: ['consumptionMonthly', 'data', 'value'],
        sorter: (a, b) => a.consumptionMonthly.data.value - b.consumptionMonthly.data.value,
        render: function (data) {
          var tag = "";
          if (data < 1024) {
            tag = "Bytes";
          }
          else if (data < 1024*1024) {
            tag = "KB";
            data = (data / 1024).toFixed(2);
          }
          else if (data < 1024*1024*1024) {
            tag = "MB";
            data = ((data / 1024) / 1024).toFixed(2);
          }
          else {
            tag = "GB";
            data = (((data / 1024) / 1024) / 1024).toFixed(2);
          }
          return (
            <p> {data} {tag} </p>
          );
        }
      },
      {
        title: lang.handle('Comienzo última conexión'),
        dataIndex: ['gprsStatus', 'lastConnStart'],
        render: function (data) {
          var d = new Date(data);
          /* Mantener != ; tiene un funcionamiento distinto a !== */
          // eslint-disable-next-line
          if (d != 'Invalid Date') {
            data = moment(d).format('HH:mm DD-MM-YYYY');
          }
          else {
            data = lang.handle('Sin información');
          }
          return <p> {data} </p>
        }
      },
      {
        title: lang.handle('Fin última conexión'),
        dataIndex: ['gprsStatus', 'lastConnStop'],
        render: function (data) {
          var d = new Date(data);
          /* Mantener != ; tiene un funcionamiento distinto a !== */
          // eslint-disable-next-line
          if (d != 'Invalid Date') {
            data = moment(d).format('HH:mm DD-MM-YYYY');
          }
          else {
            data = lang.handle('Sin información');
          }
          return <p> {data} </p>
        }
      },
      {
        title: 'IMEI',
        dataIndex: 'imei',
      },
      {
        title: lang.handle('Compañía de red'),
        dataIndex: ['presence', 'sgsn', 'operator', 'company'],
      },
      {
        title: lang.handle('Código de país'),
        dataIndex: ['presence', 'sgsn', 'operator', 'countryCode'],
      },
      {
        title: lang.handle('Tipo de red'),
        dataIndex: 'ratType',
        filters: [
          { text: '3G', value: 1 },
          { text: '2G', value: 2 },
          { text: '3.5G', value: 5 },
          { text: '4G', value: 6 },
          { text: 'NB-IoT', value: 8 }
        ],
        onFilter: (value, record) => record.ratType && record.ratType.toString().indexOf(value) === 0,
        render: function (data) {
          switch (data) {
            case 1:
              return <p> 3G </p>;

            case 2:
              return <p> 2G </p>;

            case 5:
              return <p> 3.5G </p>;

            case 6:
              return <p> 4G </p>;

            case 8:
              return <p> NB-IoT </p>;

            default:
              return <p> {lang.handle('Sin información')} </p>;
          }
        },
      },
      {
        title: lang.handle('Tipo de tarifa'),
        dataIndex: 'servicePack',
        filters: [
          { text: '500MB EU', value: 'm2m Nacional + EU 500' },
          { text: '1GB EU', value: 'm2m Nacional + EU 1GB' },
          { text: '5GB EU', value: 'm2m Nacional + EU 5GB' },
          { text: '500MB ' + lang.handle('Zona 1'), value: 'm2m Zona1 500' },
        ],
        onFilter: (value, record) => record.servicePack && record.servicePack.toLowerCase() === value.toLowerCase(),
        render: function (data) {
          switch (data) {
            case 'm2m Nacional + EU 500':
              return <p> 500MB EU </p>;

            case 'm2m Nacional + EU 1GB':
              return <p> 1GB EU </p>;

            case 'm2m Nacional + EU 5GB':
              return <p> 5GB EU </p>;

            case 'm2m Nacional + EU 10GB':
              return <p> 10GB EU </p>;

            case 'm2m Zona1 500':
              return <p> 500MB {lang.handle('Zona 1')} </p>;

            default:
              return <p> {lang.handle('Sin asignar')} </p>;
          }
        },
      },
    ];
    const { datos, loading } = this.state;
    const { stateData } = this.state;
    const textGSM = this.state.featuresGSM.text;
    const textGPRS = this.state.featuresGPRS.text;
    const featuresGSM = {
      backgroundColor: this.state.featuresGSM.background,
      color: 'white',
      fontSize: 'bolder'
    }
    const featuresGPRS = {
      backgroundColor: this.state.featuresGPRS.background,
      color: 'white',
      fontSize: 'bolder'
    }

    var iconGSM = this.state.featuresGSM.icon;
    var iconGPRS = this.state.featuresGPRS.icon;

    //Se pasan todos los nombres de organización a mayúscula
    this.state.datos.forEach(element => {
      if (element.customField1) {
        const upper = element.customField1.toUpperCase();
        element.customField1 = upper;
      }
    });

    const options = {
      scales: {
        y: {
          ticks: {
            callback: function (value, index, ticks) {
              return `${value} MB`;
            }
          }
        }
      }
    }

    return (
      <div>
        {this.state.iccAlerts.length > 0 && <Alert
          message={lang.handle("Tarjetas desactivadas desde hace mas de 12 meses")}
          description={this.state.alertDescription}
          type="warning"
          action={
            <Button onClick={this.openInfo}>
              {lang.handle('Ver más')}
            </Button>
          }
          closable
        />}
        <DataTable
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={datos}
          loading={loading}
          rowKey="icc"
        />
        <div className="button-panel">
          <Space>
            {hasPrivilege('m2m_cicle') && <Button
              type="primary"
              id="lifeCycle"
              icon={<MobileOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.showModal}>
              {lang.handle('Cambiar ciclo vida')}
            </Button>}

            {hasPrivilege('m2m_state') && <Button
              type="primary"
              id="state"
              icon={<MobileOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.showModal}>
              {lang.handle('Estado')}
            </Button>}

            {<Button
              type="primary"
              id="consume"
              icon={<MobileOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.showModal}>
              {lang.handle('Consumo')}
            </Button>}

          </Space>
        </div>

        <Modal
          title={lang.handle('Cambiar estado de la SIM') + this.state.selectedId}
          onCancel={this.handleCancel}
          cancelText={lang.handle('Cerrar')}
          onOk={this.onSubmit}
          okText={lang.handle('Cambiar')}
          icon={<ExclamationCircleOutlined />}
          open={this.state.showModal}>
          {lang.handle('Por favor, seleccione una opción')}
          <Form>
            <Form.Item>
              <Select onChange={this.handleChange}>
                <Select.Option value="DEACTIVATED">	{lang.handle('Desactivar')} </Select.Option>
                <Select.Option value="ACTIVE"> {lang.handle('Activar')} </Select.Option>
                <Select.Option value="TEST"> {lang.handle('Test')} </Select.Option>
                <Select.Option value="ACTIVATION_READY"> {lang.handle('Lista para activación')} </Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title={lang.handle('Estado de la SIM') + this.state.selectedId}
          cancelButtonProps={{ style: { display: 'none' } }}
          onCancel={this.handleCancel}
          onOk={this.handleCancel}
          okText={lang.handle('Cerrar')}
          loading={loading}
          open={this.state.showModalState}>
          <div className="row">
            <div className="col-4 arrow d-flex justify-content-around" style={featuresGSM}>
              {iconGSM}
              {lang.handle('Estado GSM')}
            </div>
            <div className="col-4 text-center">
              {textGSM}
            </div>
            <div className="col-4">
              {stateData.responseGSM.result}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-4 arrow d-flex justify-content-around" style={featuresGPRS}>
              {iconGPRS}
              {lang.handle('Estado GPRS')}
            </div>
            <div className="col-4 text-center">
              {textGPRS}
            </div>
            <div className="col-4">
              {stateData.responseGPRS.result}
            </div>
          </div>
        </Modal>
        
        <Modal
          title={lang.handle('Consumo de la SIM') + this.state.selectedId}
          onCancel={this.handleCancel}
          loading={loading}
          cancelText={lang.handle('Cerrar')}
          icon={<ExclamationCircleOutlined />}
          open={this.state.showModalConsume}
          footer={[
            <Button key="cancel" onClick={this.handleCancel}>
              {lang.handle('Cerrar')}
            </Button>
          ]}
          width={800}
          >
            <div>
              <Space size={20}>
                <RangePicker minDate={dayjs().subtract(1, 'year')} maxDate={dayjs()} onChange={this.handleDateChange} value={[
                  dayjs(this.m2m_consmption_params.start_date),
                  dayjs(this.m2m_consmption_params.end_date)
                ]}/>
                <Switch checkedChildren={lang.handle("Mensual")} unCheckedChildren={lang.handle("Diario")} checked={this.m2m_consmption_params.monthly} disabled/>
              </Space>
              <BarChart canvasId="consumeChart" data={this.state.consumes} labels={this.state.dates} options={options} dataLabel="Consumo" />
            </div>
        </Modal>

      </div>
    )
  }
}

export default withLocale(M2MList);
