import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import KiwiCard from "../components/KiwiCard";

export default function EditKiwi() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const administration_edit = hasPrivilege('administration_edit');

  useEffect(() => {
    if (!administration_edit) {
      navigate("/");
    }
  }, [navigate, administration_edit])

  if (administration_edit) {
    const editable = true;
    const ident = params.id;

    return (
      <div>
        <KiwiCard editable={editable} ident={ident} location={location}/>
      </div>
    )
  }
}
