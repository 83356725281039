import React, { Component } from 'react';
import "./BarChart.scss";
import Chart from 'chart.js/auto';
import { withLocale } from '../../l10n';

class BarChart extends Component {

  state = {
    canvasId: "myChart" + this.props.canvasId
  }

  chart = null;

  componentDidUpdate(prevProps) {
    // Verificar si las props relevantes han cambiado antes de recrear el gráfico
    if (prevProps.labels !== this.props.labels || prevProps.data !== this.props.data || prevProps.options !== this.props.options) {
      this.updateChart();
    }
  }

  updateChart() {
    if (this.chart) {
      this.chart.data.labels = this.props.labels;
      this.chart.data.datasets[0].data = this.props.data;
      this.chart.options = this.props.options;
      this.chart.update();
    } else {
      this.createChart();
    }
  }

  createChart() {
    const ctx = document.getElementById(this.state.canvasId);

    if (ctx) {
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.props.labels,
          datasets: [{
            label: this.props.lang.handle(this.props.dataLabel),
            data: this.props.data,
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderColor: 'rgba(54, 162, 235, 0.1)',
            borderWidth: 1
          }]
        },
        options: this.props.options
      });
    } else {
      console.error(`Element with ID ${this.state.canvasId} not found.`);
    }
  }

  render() {
    const { canvasId } = this.state;

    return (
      <canvas id={canvasId}></canvas>
    )
  }
}

export default withLocale(BarChart);
