import React, { useState } from "react";
import { Outlet, useNavigate} from "react-router-dom";
import { Layout } from "antd";
import MenuTop from "../components/MenuTop";
import MenuSider from "../components/MenuSider";
import useAuth from "../hooks/useAuth";

import "../components/styles/MainLayout.scss";

export default function MainLayout(props) {
  // const { match, routes } = props;
  const navigate = useNavigate();
  const { location } = props;
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const { Header, Content, Footer } = Layout;
  // isLoading indica si el proceso useAuth() está en marcha
  // useAuth comprueba que el usuario está logeado
  const { user, isLoading } = useAuth();

  // si no hay usuario logeado y no se está comprobando
  if (!user && !isLoading) { navigate("/login") }

  // si hay usuario logeado y no se está comprobando
  if (user && !isLoading) {
    return (
      <Layout>
        <MenuSider menuCollapsed={menuCollapsed} location={location} />
        <Layout
          className="layout-admin"
          style={{ marginLeft: menuCollapsed ? "80px" : "200px" }}
        >
          <Content className="layout-admin__content">
            <Outlet />
          </Content>
          <Header className="layout-admin__header" style={{zIndex: 3}} >
            <MenuTop
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
              homeUrl={location.path}
            />
          </Header>

          <Footer className="layout-admin__footer" style={{ textAlign: 'center' }}>
            Kiconex Server © 2018-{new Date().getFullYear()} Created by
            <a href="http://kiconex.es"> kiconex </a>
          </Footer>
        </Layout>
      </Layout >
    );
  }

  return null;
}
