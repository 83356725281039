import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import ActivitiesTable from '../components/ActivitiesTable';

export default function Activities() {
  const navigate = useNavigate();
  const activities_view = hasPrivilege('activity_view');

  useEffect(() => {
    if (!activities_view) {
      navigate("/");
    }
  }, [navigate, activities_view])

  if (activities_view) {
    return (
      <div>
        <ActivitiesTable />
      </div>
    )
  }
}
