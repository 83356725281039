import React from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { hasPrivilege } from "../../api/token";

import {
  HomeOutlined, UserOutlined, UserAddOutlined, LaptopOutlined, ShopOutlined, UnorderedListOutlined, PhoneOutlined,
  EuroOutlined, ApartmentOutlined, TagsOutlined, BarsOutlined, FileAddOutlined, ToolOutlined, ControlOutlined, WechatOutlined,
  SettingOutlined, KeyOutlined, UsergroupAddOutlined, InboxOutlined, ClockCircleOutlined, ScheduleOutlined, InfoCircleOutlined
} from "@ant-design/icons";

import "./MenuSider.scss";
import { withLocale } from "../../l10n";

function MenuSider(props) {
  const { menuCollapsed, location, lang } = props;
  const { Sider } = Layout; //La alternativa a esta línea es usar Layout.Sider en las siguientes líneas
  const path = location.pathname;
  const menuItems = [
    {
      key: '/',
      icon: <HomeOutlined />,
      label: <Link to={"/"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Inicio')} </span>
      </Link>
    },
    (hasPrivilege('manufacture_create') || hasPrivilege('manufacture_view') || hasPrivilege('assign_view')) && {
      icon: <ToolOutlined />,
      label: lang.handle('Fábrica'),
      children: [
        hasPrivilege('manufacture_create') && {
          key: '/boards/manufacture/new',
          icon: <FileAddOutlined />,
          label: <Link to={"/boards/manufacture/new"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Nuevo equipo')} </span></Link>
        },
        hasPrivilege('manufacture_view') && {
          key: '/boards/manufacture/list',
          icon: <BarsOutlined />,
          label: <Link to={"/boards/manufacture/list"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Stock')} </span></Link>
        },
        hasPrivilege('assign_view') && {
          key: '/boards/manufacture/assigned',
          icon: <ControlOutlined />,
          label: <Link to={"/boards/manufacture/assigned"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Asignaciones')} </span></Link>
        }
      ]
    },
    (hasPrivilege('administration_create') || hasPrivilege('administration_view')) && {
      icon: <LaptopOutlined />,
      label: lang.handle('Administración'),
      children: [
        hasPrivilege('administration_create') && {
          key: '/boards/new',
          icon: <FileAddOutlined />,
          label: <Link to={"/boards/new"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Nuevo equipo')} </span></Link>
        },
        hasPrivilege('administration_view') && {
          key: '/boards/list',
          icon: <BarsOutlined />,
          label: <Link to={"/boards/list"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Lista equipos')} </span></Link>
        },
        hasPrivilege('administration_renewal') && {
          key: '/boards/renewals',
          icon: <ClockCircleOutlined />,
          label: <Link to={"/boards/renewals"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Renovaciones')} </span></Link>
        }
      ]
    },
    hasPrivilege('orders_view') && {
      key: '/orders/list',
      icon: <InboxOutlined />,
      label: <Link to={"/orders/list"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Pedidos')} </span>
      </Link>
    },
    hasPrivilege('mykiconex_view') && {
      key: '/devices/list',
      icon: <ShopOutlined />,
      label: <Link to={"/devices/list"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Instalaciones')} </span>
      </Link>
    },
    (hasPrivilege('m2m_view') || hasPrivilege('pools_view')) && {
      icon: <PhoneOutlined />,
      label: lang.handle('Gestión') + " M2M",
      children: [
        hasPrivilege('m2m_view') && {
          key: '/simcards/m2m',
          icon: <UnorderedListOutlined />,
          label: <Link to={"/simcards/m2m"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Listado M2M')} </span></Link>
        },
        hasPrivilege('pools_view') && {
          key: '/simcards/pools',
          icon: <EuroOutlined />,
          label: <Link to={"/simcards/pools"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Listado Pools')} </span></Link>
        }
      ]
    },
    hasPrivilege('vpn_view_old') && {
      key: '/vpn',
      icon: <ApartmentOutlined />,
      label: <Link to={"/vpn"} style={{ textDecoration: 'none' }}><span className="nav-text"> VPN </span></Link>
    },
    hasPrivilege('support_view') && {
      key: '/support',
      icon: <TagsOutlined />,
      label: <Link to={"/support"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Soporte')} </span></Link>
    },
    hasPrivilege('chat_view') && {
      key: '/chat',
      icon: <WechatOutlined />,
      label: <Link to={"/chat"} style={{ textDecoration: 'none' }}><span className="nav-text"> Chat </span></Link>
    },
    (hasPrivilege('users_create') || hasPrivilege('users_view') || hasPrivilege('roles_settings')) && {
      icon: <ToolOutlined />,
      label: lang.handle('Usuarios'),
      children: [
        hasPrivilege('users_create') && {
          key: '/users/create',
          icon: <UserAddOutlined />,
          label: <Link to={"/users/create"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Crear Usuario')} </span></Link>
        },
        hasPrivilege('users_view') && {
          key: '/users/list',
          icon: <UserOutlined />,
          label: <Link to={"/users/list"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Lista Usuarios')} </span></Link>
        },
        hasPrivilege('roles_settings') && {
          key: '/users/roles',
          icon: <UsergroupAddOutlined />,
          label: <Link to={"/users/roles"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Roles')} </span></Link>
        }
      ]
    },
    (hasPrivilege('privileges_view') || hasPrivilege('vpn_view') || hasPrivilege('info_view') || hasPrivilege('activity_view') || hasPrivilege('settings')) && {
      icon: <SettingOutlined />,
      label: lang.handle('Sistema'),
      children: [
        hasPrivilege('privileges_view') && {
          key: '/system/privileges',
          icon: <KeyOutlined />,
          label: <Link to={"/system/privileges"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Privilegios')} </span></Link>
        },
        hasPrivilege('vpn_view') && {
          key: '/system/vpn',
          icon: <ApartmentOutlined />,
          label: <Link to={"/system/vpn"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('VPN')} </span></Link>
        },
        hasPrivilege('info_view') && {
          key: '/system/info',
          icon: <InfoCircleOutlined />,
          label: <Link to={"/system/info"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Información placas')} </span></Link>
        },
        hasPrivilege('activity_view') && {
          key: '/system/activities',
          icon: <ScheduleOutlined />,
          label: <Link to={"/system/activities"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Actividad')} </span></Link>
        },
        hasPrivilege('settings') && {
          key: '/system/settings',
          icon: <SettingOutlined />,
          label: <Link to={"/system/settings"} style={{ textDecoration: 'none' }}><span className="nav-text"> {lang.handle('Ajustes')} </span></Link>
        },
      ]
    },
  ]

  return (
    <Sider className="menu-sider" collapsed={menuCollapsed}>
      <Menu theme="dark" mode="inline" selectedKeys={path} items={menuItems} />
    </Sider>
  );
}

export default withLocale(MenuSider);
