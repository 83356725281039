import React, { Component } from 'react';
import "./m2mDetails.scss";
import service from "../../services/Service";
import { notification } from 'antd';
import Loading from "../../utils/loading";
import { withLocale } from '../../l10n';
class m2mDetails extends Component {
	state = {
		datos: {},
		icc:'',
		loading: false,
		botonDisabled: true,
		selectedId: '',
		showModal: false,
		lifeCycle: '',
		lang: this.props.lang
	};

	async componentDidMount() {
		let icc;
		// Si es una placa cargada desde el listado de placas, se obtiene mediante su id
		if (this.props.identUUID) {
			this.setState({loading: true})
			await service.get('boards/uuid/'+ this.props.identUUID)
			.then(res => {
				if (res.data.ok === false) {
					notification["error"]({
						message: this.props.lang.handle(res.data.message),
					});
					return;
				}

				this.setState({
					icc: res.data.icc,
					loading: false
				});					
				icc = res.data.icc;
			})
			.catch(err => {
				console.log('Error devuelto: ' + err);
			})
		}
		if (icc) {
			this.setState({loading: true})
			await service.get('m2m/' + icc)
				.then(res => {
					if (res.data.ok === false) {
            notification["error"]({
              message: this.props.lang.handle(res.data.message),
            });
            return;
          }

					this.setState({
						loading: false,
						datos: res.data,
					}) 
				})
				.catch(err => console.log('Error: ', err))
		}	
	}
	
	RangoConsumo (data) {
		var tag = "";
		var texto= "";
		if (data < 1024){
			tag = "bytes";
		}
		else if (data < 1024*1024){
			tag = "KB";
			data = (data/1024).toFixed(2);
		}
		else if (data < 1024*1024*1024){
			tag = "MB";
			data = ((data/1024) / 1024).toFixed(2);
		}
		else {
			tag = "GB";
			data = (((data/1024) / 1024) / 1024).toFixed(2);
		}
		texto = data + ' ' + tag;
		return (
			texto
		)
	};
	TecnologiaConexion(data){
		var texto="";

		const { lang } = this.state;
		switch (data){
			case 1: 
				texto ="3G";
				break;
			case 2:
				texto ="2G";
				break;
			case 5:
				texto ="3.5G";
				break;
			case 6:
				texto ="4G";
				break;
			case 8:
				texto ="NB-IoT";
				break;
			default:
				texto=lang.handle('error');
				break;
			
		}
		return(texto)
	};

	render() {
		const { lang, loading} = this.state;
		 return (
			<div className="card">
				{loading && <Loading loading={loading}/>}
				{/* Titulo de la tarjeta */}
				<h4 className="card-title p-3"> { lang.handle('Información de tarjeta M2M con icc') + ': ' + this.state.icc } </h4>
	
				{/* Formulario con los datos a cumplimentar */}
				<form onSubmit = { this.onSubmit } className='p-3'>
	
						{/* alias tarjeta */}
						<div className = "form-group row">
							<label htmlFor = "alias" className = "col-sm-2 col-form-label newCard-form-text"> {lang.handle('Alias')} </label>
							<div className = "col-sm-10">
							<input
								type = "text"
								className = "form-control"
								id = "alias"
								placeholder = "20PED4596"
								onChange = { this.onInputChange }
								name = "alias"
								value = { this.state.datos.alias ? this.state.datos.alias : "" } 
								disabled = { !this.props.editable }
								required
								/>
							</div>
						</div>
						{/* lifeCycle status */}
						<div className = "form-group row">
							<label htmlFor = "lifecycle" className = "col-sm-2 col-form-label newCard-form-text"> {lang.handle('Life Cycle Status')} </label>
							<div className = "col-sm-10">
							<input
								type = "text"
								className = "form-control"
								id = "lifecycle"
								placeholder = "20PED4596"
								onChange = { this.onInputChange }
								name = "lifecycle"
								value = { this.state.datos.lifeCycleStatus ? this.state.datos.lifeCycleStatus : "" } 
								disabled = { !this.props.editable }
								required
								/>
							</div>
						</div>
						{/* cliente */}
						<div className = "form-group row">
							<label htmlFor = "cliente" className = "col-sm-2 col-form-label newCard-form-text"> {lang.handle('Cliente')} </label>
							<div className = "col-sm-10">
							<input
								type = "text"
								className = "form-control"
								id = "cliente"
								placeholder = "20PED4596"
								onChange = { this.onInputChange }
								name = "cliente"
								value = { this.state.datos.customField1 ? this.state.datos.customField1 : "" } 
								disabled = { !this.props.editable }
								required
								/>
							</div>
						</div>
						{/* Consumos */}
						<div className = "form-group row">
							{/* Diarios */}
							<div className = "col-sm-2 newCard-form-text"> {lang.handle('Consumo diario')} </div>
							<div className = "col-sm-4">
								<input
									className = "form-control"
									onChange = { this.onInputChange }
									name = "consumoDiario"
									value =  { this.RangoConsumo(this.state.datos.consumptionDaily?this.state.datos.consumptionDaily.data.value:"") }
									disabled = {true}
								/>
							</div>

							{/* Mensuales */}
							<div className = "col-sm-2 newCard-form-text"> {lang.handle('Consumo mensual')} </div>
							<div className = "col-sm-4">
								<input
									className = "form-control"
									onChange = { this.onInputChange }
									name = "consumoMensual"
									value =  { this.RangoConsumo(this.state.datos.consumptionMonthly?this.state.datos.consumptionMonthly.data.value:"") }
									disabled = {true}
								/>
							</div>
						</div>
						{/* Operador-Pais */}
						<div className = "form-group row">
							{/* Operador */}
							<div className = "col-sm-2 newCard-form-text"> {lang.handle('Operador')} </div>
							<div className = "col-sm-4">
								<input
									type = "text"
									className = "form-control"
									onChange = { this.onInputChange }
									name = "operador"
									value =  { this.state.datos.operator ? this.state.datos.operator : "" }
									disabled = {true}
								/>
							</div>

							{/* Pais */}
							<div className = "col-sm-2 newCard-form-text"> {lang.handle('Pais')} </div>
							<div className = "col-sm-4">
								<input
									type = "text"
									className = "form-control"
									onChange = { this.onInputChange }
									name = "pais"
									value =  { this.state.datos.country ? this.state.datos.country : "" }
									disabled = {true}
								/>
							</div>
						</div>
						{/* PLAN */}
						<div className = "form-group row">
							{/* PLAN */}
							<div className = "col-sm-2 newCard-form-text"> {lang.handle('Plan')} </div>
							<div className = "col-sm-4">
								<input
									type = "text"
									className = "form-control"
									onChange = { this.onInputChange }
									name = "subscription"
									value =  { this.state.datos.servicePack ? this.state.datos.servicePack : ""}
									disabled = {true}
								/>
							</div>

							{/* POOL */}
							<div className = "col-sm-2 newCard-form-text"> Pool </div>
							<div className = "col-sm-4">
								<input
									type = "text"
									className = "form-control"
									onChange = { this.onInputChange }
									name = "pool"
									value =  { this.state.datos.commercialGroup ? this.state.datos.commercialGroup : "" }
									disabled = {true}
								/>
							</div>
						</div>
						{/* ESTADO GPRS */}
						<div className = "form-group row">
							{/* Estado */}
							<div className = "col-sm-2 newCard-form-text"> {lang.handle('Estado Actual')} </div>
							<div className = "col-sm-2">
								<input
									type = "text"
									className = "form-control"
									value =  { this.state.datos.presence ?this.state.datos.presence.level:"" }
									disabled = {true}
								/>
							</div>
							{/* GPRS */}
							<div className = "col-sm-2 newCard-form-text"> {lang.handle('Último evento')} </div>
							<div className = "col-sm-2">
								<input
									type = "text"
									className = "form-control"
									value =  { this.state.datos.presence ?this.state.datos.presence.cause:""  }
									disabled = {true}
								/>
							</div>
							{/* Tipo Conexión */}
							<div className = "col-sm-2 newCard-form-text"> {lang.handle('Tecno.Conexión')} </div>
							<div className = "col-sm-2">
								<input
									type = "text"
									className = "form-control"
									value =  { this.TecnologiaConexion(this.state.datos.presence ?this.state.datos.presence.ratType:"" ) }
									disabled = {true}
								/>
							</div>
						</div>
						<div className = "form-group row">
							{/* Time */}
							<div className = "col-sm-2 newCard-form-text"> {lang.handle('Última Conexión')} </div>
							<div className = "col-sm-4">
								<input
									type = "text"
									className = "form-control"
									value =  { this.state.datos.presence ?this.state.datos.presence.timestamp:""  }
									disabled = {true}
								/>
							</div>
							
						</div>
				</form>
			</div>		
		)
	};
}

export default withLocale(m2mDetails);
