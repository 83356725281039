import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import { Tabs } from "antd";
import BoardCard from "../components/BoardCard";
import KiwiCard from "../components/KiwiCard"
import { withLocale } from './../l10n';
function NewBoard(props) {
  const navigate = useNavigate();
  const administration_create = hasPrivilege('administration_create');

  useEffect(() => {
    if (!administration_create) {
      navigate("/");
    }
  }, [navigate, administration_create])

  const params = useParams();
  const editable = true;
  const newBoard = true;

  const items = [
    { label: props.lang.handle('Nuevo kibox'), key: '1', children: <BoardCard editable={editable} newBoard={newBoard} /> },
    { label: props.lang.handle('Nuevo kiwi'), key: '2', children: <KiwiCard editable={editable} newBoard={newBoard} /> },
  ]

  if (administration_create) {
    return (<Tabs
      type="card"
      activeKey={params.tab}
      items={items}
      onChange={key => {
        navigate(`/boards/new/${key}`)
      }} />)
  }
}
export default withLocale(NewBoard);
