import React, { Component } from "react";
import "./ManufactureKiwi.scss";
import { notification, DatePicker } from "antd";
import { macValidation, ssidValidation } from "../../../utils/formValidation";
import service from "../../../services/Service";
import moment from "moment";
import dayjs from 'dayjs';
import { withLocale } from "../../../l10n";
import Loading from "../../../utils/loading";
class ManufactureKiwi extends Component {
  // Se inicia el vector de estados del componente
  state = {
    ssid: "",
    mac: "",
    date: new Date(),
    notes: "",
    state: "Fabricado",
    software: "v1.3.0",
    errors: {
      ssid: "",
      mac: "",
    },
    lang: this.props.lang,
    loading: false
  };

  // Funcion que guarda el valor introducido en el formulario dentro del campo correspondiente de state
  onInputChange = (e) => {
    const { name, value } = e.target;
    const { lang } = this.state;
    let errors = this.state.errors;
    switch (name) {
      case "ssid":
        errors.ssid = ssidValidation(value) ? "" : lang.handle('¡SSID erróneo!');
        break;
      case "mac":
        errors.mac = macValidation(value) ? "" : lang.handle('¡MAC errónea!');
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };

  // Funcion que guarda el valor escogido en el calendario dentro del campo correspondiente de state
  onChangeDate = (date, dateString) => {
    this.setState({ date: dateString });
  };

  // Funcion que envia la informacion a la base de datos. Si es una placa nueva la crea, si es una antigua la actualiza
  onSubmit = async (e) => {
    // Se evita cargar el valor por defecto del state
    e.preventDefault();
    const urlStockKibox = "/boards/manufacture/list/2";
    const boardData = this.state;
    const errors = this.state.errors;

    if (boardData.date instanceof Date) {
      boardData.date = moment(this.state.date).format("DD-MM-YYYY");
    }

    // Se guarda el estado del componente en una variable para enviarla a la base de datos
    if (!errors.ssid && !errors.mac) {
      if (this.props.newBoard) {
        await service
          .post("kiwi", boardData)
          .then((res) => {
            if (res.data.ok === false) {
              notification["error"]({
                message: this.props.lang.handle(res.data.message),
              });
              return;
            } else {
              notification["success"]({
                message: this.props.lang.handle('Placa creada correctamente'),
              });
              window.location.href = urlStockKibox;
            }

          })
          .catch((err) => {
            console.log("Error: " + err);
          });
      }
      // Si es una placa recuperada, se actualiza su valor
      else {
        await service
          .put("kiwi/" + this.state.uuid, boardData)
          .then((res) => {
            if (res.data.ok === false) {
              notification["error"]({
                message: this.props.lang.handle(res.data.message),
              });
              return;
            } else {
              notification["success"]({
                message: this.props.lang.handle('Placa actualizada correctamente'),
              });
              window.location.href = urlStockKibox;
            }
          })
          .catch((err) => {
            console.log("Error devuelto: " + err);
          });
      }
    } else {
      if (errors.mac) {
        notification["error"]({
          message: this.props.lang.handle('MAC errónea'),
        });
      }
      if (errors.ssid) {
        notification["error"]({
          message: this.props.lang.handle('SSID erróneo'),
        });
      }
    }
  };

  // Funcion que carga la información recogida de la base de datos en el componente
  async componentDidMount() {
    // Si es una placa cargada desde el listado de instalaciones, se obtiene mediante su id
    if (this.props.ident) {
      this.setState({ loading: true })
      await service
        .get("kiwi/" + this.props.ident)
        .then((res) => {
          if (res.data.ok === false) {
            notification["error"]({
              message: this.props.lang.handle(res.data.message),
            });
            return;
          }
          const data = res.data[0];
          this.setState({
            order_id: data.order_id,
            serial_number: data.serial_number,
            work_order: data.work_order,
            ssid: data.ssid,
            mac: data.mac,
            uuid: data.uuid,
            date: new Date(data.date),
            software: data.software,
            state: data.state,
            notes: data.notes,
            _id: data._id,
            loading: false
          });
        })
        .catch((err) => {
          console.log("Error devuelto: " + err);
        });
    }
  }

  // Se genera el componente
  render() {
    // Variable que lleva el titulo de la tarjeta. Se pasa un valor en funcion de si es un elemento nuevo o uno antiguo
    var title = "";
    const { lang } = this.state;
    // Se evalua la propiedad "newBoard" del componente. En funcion de esta, se muestra un titulo u otro en la tarjeta
    if (this.props.newBoard === true) {
      title = lang.handle('Nuevo kiwi');
    } else {
      title = lang.handle('Información de kiwi') + " " + this.state.ssid;
    }

    // Funcion que crea el boton de guardado
    function Save({ save }) {
      if (save === true) {
        return (
          /* Boton del formulario */
          <button
            id="button-save"
            className="btn btn-primary d-flex float-right"
            type="submit"
          >
            <div className="btnText">
              <p> {lang.handle('Guardar')}&nbsp;&nbsp; </p>
              <i className="material-icons"> save </i>
            </div>
          </button>
        );
      } else {
        return null;
      }
    }

    const { errors, loading } = this.state;

    return (
      <div className="card">
        {loading && <Loading loading={loading} />}
        <h4 className="p-3"> {title} </h4>
        <form onSubmit={this.onSubmit} className="p-3">
          {/* SSID placa */}
          <div className="form-group row">
            <label
              htmlFor="ssid"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              SSID{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="ssid"
                placeholder="KIWI_A5BD67"
                onChange={this.onInputChange}
                name="ssid"
                value={this.state.ssid}
                disabled={!this.props.editable}
                required
              />
              {errors.ssid.length > 0 && (
                <span className="boardFormError">{errors.ssid}</span>
              )}
            </div>
          </div>

          {/* MAC */}
          <div className="form-group row">
            <label
              htmlFor="mac"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              MAC{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="mac"
                placeholder="30:A4:F5:20:4C:5A"
                onChange={this.onInputChange}
                name="mac"
                value={this.state.mac}
                disabled={!this.props.editable}
                required
              />
              {errors.mac.length > 0 && (
                <span className="boardFormError">{errors.mac}</span>
              )}
            </div>
          </div>

          {/* Fecha Placa */}
          <div className="form-group row">
            <div className="col newCard-form-text"> {lang.handle('Fecha fabricación')} </div>
            <div className="col-sm-10">
              <DatePicker
                className="form-control"
                value={dayjs(moment(this.state.date, "DD-MM-YYYY"))}
                onChange={this.onChangeDate}
                format="DD-MM-YYYY"
                disabled={!this.props.editable}
                required
              />
            </div>
          </div>

          {/* Notas */}
          <div className="form-group row">
            <label
              htmlFor="notes"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              {lang.handle('Notas')}{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="notes"
                placeholder={lang.handle('No se conecta al WiFi')}
                onChange={this.onInputChange}
                name="notes"
                value={this.state.notes}
                disabled={!this.props.editable}
              />
            </div>
          </div>

          <Save save={this.props.editable} />
        </form>
      </div>
    );
  }
}

export default withLocale(ManufactureKiwi);
