import { configureStore } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const storeSlice = createSlice({
  name: 'store',
  initialState: { 
    alerts: { 
      expired_info: {}, 
      pending_info: {},
      alarms_m2m: {}
    }, 
  },
  reducers: {
    saveExpiredInfo: (state, action) => {
      state.alerts.expired_info = action.payload.expired;
    },
    savePendingInfo: (state, action) => {
      state.alerts.pending_info = action.payload.pending;
    },
    saveM2MInfo: (state, action) => {
      state.alerts.alarms_m2m = action.payload.alarmsActived;
    },
  }

});

const store = configureStore({
  reducer: {
    store: storeSlice.reducer
  }
});

export const { saveExpiredInfo, savePendingInfo, saveM2MInfo } = storeSlice.actions;

export default store;
