import React, { useEffect } from "react";
import { Spin } from 'antd';
const Loading = ({ loading }) => {

    function chekLoading() {
        return true
    }
    useEffect(() => {
        chekLoading();
    }, [loading])

    return (
        <div
            style={{
                backgroundColor: '#E5F0EC',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                opacity: '0.5',
                position: 'absolute',
                height: '100%',
                width: '100%',
                zIndex: 2,
            }}>
            <Spin size="large" />
        </div>
    )
}

export default Loading;
