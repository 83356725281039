import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import UsersList from "../components/UsersList";

export default function ListaUsuarios() {
  const navigate = useNavigate();
  const users_view = hasPrivilege('users_view');

  useEffect(() => {
    if (!users_view) {
      navigate("/");
    }
  }, [navigate, users_view])

  if (users_view) {
    return (
      <div>
        <UsersList />
      </div>
    )
  }
}
