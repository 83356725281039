import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import ManufactureKiwi from "./components/ManufactureKiwi/ManufactureKiwi";

export default function ManufactureEditKiwi() {
  const navigate = useNavigate();
  const params = useParams();
  const manufacture_edit = hasPrivilege('manufacture_edit');

  useEffect(() => {
    if (!manufacture_edit) {
      navigate("/");
    }
  }, [navigate, manufacture_edit])

  if (manufacture_edit) {
    const editable = true;
    const ident = params.id;

    return (
      <div>
        <ManufactureKiwi editable={editable} ident={ident} />
      </div>
    )
  }
}
