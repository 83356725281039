import React, { useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "../index.css";
import { hasPrivilege } from "../api/token";
import M2MList from "../components/m2mList";

export default function GestionM2M() {
  const navigate = useNavigate();
  const location = useLocation();
  const m2m_view = hasPrivilege('m2m_view');
  const params = useParams();

  useEffect(() => {
    if (!m2m_view) {
      navigate("/");
    }
  }, [navigate, m2m_view])

  if (m2m_view) {
    location.id = params.id;

    return (
      <div>
        <M2MList location={location}/>
      </div>
    )
  }
}
