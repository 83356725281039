import React, { Component } from 'react';
import service from "../../services/Service";
import { Button, notification } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import Loading from "../../utils/loading";

import { withLocale } from '../../l10n';

class SettingsCRM extends Component {
  state = {
    loading: false,
  };

  refreshOrders = async () => {
    this.setState({ loading: true });
    await service
      .post("orders/refresh")
      .then((res) => {
        this.setState({ loading: false });
        notification["success"]({
          message: this.props.lang.handle("Actualizado con exito"),
        });
      })
      .catch((err) => {
        console.log("Error devuelto: " + err);
      });
  }

  render() {
    const loading = this.state.loading;

    return (
      <div>
        {loading && <Loading loading={loading} />}
        <div className='card card-body'>
          <h4 className="p-3"> {this.props.lang.handle("Actualización de listados")} </h4>

          <div className="row">
            <div className="col col-sm-3 my-auto">
              <div>{this.props.lang.handle("Actualizar listado de pedidos")}</div>
            </div>
            <div className="col col-sm-9">
              <Button
                type="primary"
                icon={<ReloadOutlined />}
                onClick={this.refreshOrders}
              >
                {this.props.lang.handle('Refrescar pedidos')}
              </Button>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default withLocale(SettingsCRM);
