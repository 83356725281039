import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import OrdersList from "../components/OrdersList";

export default function Orders() {
  const navigate = useNavigate();
  const location = useLocation();
  const orders_view = hasPrivilege('orders_view');

  useEffect(() => {
    if (!orders_view) {
      navigate("/");
    }
  }, [navigate, orders_view])

  if (orders_view) {
    return (
      <div>
        <OrdersList location={location}/>
      </div>
    )
  }
}
