import React from "react";
import { notification, Input, Button, Space } from "antd";
import { SearchOutlined, FormOutlined, BookOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import "./KiboxManufactureList.scss";
import { hasPrivilege } from "../../api/token";
import service from "../../services/Service";
import moment from "moment";
import { withLocale } from "../../l10n";
import DataTable from "../DataTable/DataTable";
import DeleteButton from "../DeleteButton/DeleteButton";

class KiboxManufactureList extends React.Component {
  state = {
    datos: [],
    loading: false,
    botonDisabled: true,
    selectedId: "",
    lang: this.props.lang,
  };

  getColumnSearchProps = (dataIndex, lang) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {lang.handle('Buscar')}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {lang.handle('Borrar')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  async componentDidMount() {
    this.Pfetch({});
  }

  Pfetch = async (params = {}) => {
    this.setState({ loading: true });
    await service
      .get("boards/manufacture/Fabricado")
      .then((res) => {
        if (res.data.ok === false) {
          notification["error"]({
            message: this.props.lang.handle(res.data.message),
          });
          this.setState({
            datos: [],
          });
        } else {
          this.setState({
            datos: res.data,
          });
        }
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        console.log("Error devuelto: " + err);
      });
  };

  deletePlaca = async () => {
    const { lang } = this.state;
    this.setState({ loading: true });
    await service
      .del("boards/" + this.state.selectedId, this.state.selectedId)
      .then((res) => {
        if (res.data.ok === false) {
          notification["error"]({
            message: lang.handle(res.data.message),
          });
        } else {
          notification["success"]({
            message: lang.handle('Placa borrada correctamente'),
          });
        }
        this.Pfetch({});
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log("Error devuelto: " + err);
      });
  };

  updatePlaca = () => {
    this.setState({ loading: true });
    window.location.href =
      "/boards/manufacture/edit/1/" + this.state.selectedId;
  };

  assignPlaca = () => {
    this.setState({ loading: true });
    window.location.href =
      "/boards/manufacture/assign/" + this.state.selectedId;
  };

  render() {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          botonDisabled: false,
          selectedId: selectedRows[0].ki_id,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    const { lang } = this.state;

    const columns = [
      // { uuid, hardware_version, mac, date, oem, model, usb, subscription, devices_number, icc, optionals, notes }
      {
        title: 'Id',
        dataIndex: "ki_id",
        ...this.getColumnSearchProps("ki_id", this.props.lang),
      },
      {
        title: "UUID",
        dataIndex: "uuid",
        ...this.getColumnSearchProps("uuid", lang),
      },
      {
        title: lang.handle('Version hardware'),
        dataIndex: "hardware_version",
      },
      {
        title: "MAC",
        dataIndex: "mac",
        ...this.getColumnSearchProps("mac", lang),
      },
      {
        title: lang.handle('Fecha fabricación'),
        dataIndex: "dateFormated",
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: lang.handle('Modelo'),
        dataIndex: "model",
        filters: [
          { text: "Kibox0", value: "Kibox0" },
          { text: "Kibox1", value: "Kibox1" },
          { text: "Kibox1A", value: "Kibox1A" },
          { text: "Kibox1E", value: "Kibox1E" },
          { text: "Kibox2", value: "Kibox2" },
          { text: "Kibox2A", value: "Kibox2A" },
          { text: "Kibox4", value: "Kibox4" },
          { text: "Kibox8", value: "Kibox8" },
          { text: "Kibox-Lite", value: "Kibox-Lite" },
          { text: "Kibox-CAN", value: "Kibox-CAN" },
        ],
        onFilter: (value, record) =>
          record.model && record.model.toLowerCase() === value.toLowerCase(),
        sorter: (a, b) => a.model && a.model.length - b.model.length,
        sortDirections: ["descend"],
      },
      {
        title: lang.handle('Tipo USB'),
        dataIndex: "usb",
        filters: [
          { text: this.props.lang.handle('Ninguno'), value: 'Ninguno' },
          { text: this.props.lang.handle('Chino'), value: 'Chino' },
          { text: "FTDI", value: "FTDI" },
          { text: "USB-COM4", value: "USB-COM4" },
          { text: "SHIELD-V1.0.1-2P", value: "SHIELD-V1.0.1-2P" },
          { text: "SHIELD-V1.0.1-4P", value: "SHIELD-V1.0.1-4P" },
          { text: "SHIELD-V1.0.1-CAN", value: "SHIELD-V1.0.1-CAN" },
        ],
        onFilter: (value, record) =>
          record.usb && record.usb.toLowerCase() === value.toLowerCase(),
      },
      {
        title: lang.handle('Notas'),
        dataIndex: "notes",
      },
    ];
    const { datos, loading } = this.state;

    let selection = null;
    if (hasPrivilege('manufacture_delete') || hasPrivilege('manufacture_edit') || hasPrivilege('assign')) {
      selection =
      {
        type: 'radio',
        ...rowSelection,
      };
    }

    datos.map((el) => {
      let date = new Date(el.date);
      return (el.dateFormated = moment(date).format('DD-MM-YYYY'));
    });

    return (
      <div>
        <DataTable
          columns={columns}
          dataSource={datos}
          rowKey="ki_id"
          rowSelection={selection}
          loading={loading}
        />
        <div className="button-panel">
          <Space>

            {hasPrivilege('manufacture_delete') &&
              <DeleteButton
                onEvent={this.deletePlaca}
                buttonDisabled={this.state.botonDisabled}
              />}

            {hasPrivilege('manufacture_edit') && <Button
              type="primary"
              icon={<FormOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.updatePlaca}
            >
              {lang.handle('Editar')}
            </Button>}
            {hasPrivilege('assign') && <Button
              type="primary"
              icon={<BookOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.assignPlaca}
            >
              {lang.handle('Asignar')}
            </Button>}
          </Space>
        </div>
      </div>
    );
  }
}

export default withLocale(KiboxManufactureList);
//ReactDOM.render(<App />, document.getElementById('container'));
