import React, { Component } from "react";
import { Input, Button, Space, Modal, Form, Select, notification, Checkbox, Divider } from "antd";
import { SearchOutlined, SwapOutlined, ExclamationCircleOutlined, FilterFilled } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import service from "../../services/Service";
import moment from "moment";
import BotonIrCRM from "../BotonIrCRM/BotonIrCRM";
import DataTable from "../DataTable/DataTable";
import { withLocale } from "../../l10n";
import "./OrdersList.scss";

class OrdersList extends Component {

  ORDERS_STATE = [
    {
      label: this.props.lang.handle("Pendiente"),
      value: 0
    },
    {
      label: this.props.lang.handle("En Proceso"),
      value: 1
    },
    {
      label: this.props.lang.handle("Terminado"),
      value: 2
    },
    {
      label: this.props.lang.handle("Eliminado"),
      value: 3
    },
    {
      label: this.props.lang.handle("Anulado"),
      value: 4
    }
  ];

  state = {
    orders: [],
    loading: false,
    botonDisabled: true,
    selectedId: "",
    selectedSalesorder_no: "",
    changeState: "",
    showModal: false,
    showModalState: false,
    lang: this.props.lang,
    searchedColumns: this.props.location.state ? [0] : [],
    states: this.ORDERS_STATE
  };

  // Funciones que muestran y ocultan los modales dependiendo del id
  showModal = async (e) => {
    const item = this.state.orders.find(e => e.salesorder_no === this.state.selectedSalesorder_no);
    this.setState({
      showModal: true,
      changeState: item.crm_status
    });
  };

  hideModal = async (e) => {
    this.setState({ showModal: false });
  };

  // Funcion que cierra el modal al pulsar cancel
  handleCancel = () => {
    this.setState({ changeState: '' });
    this.setState({ showModal: false, showModalState: false });
  };

  handleChange = (value) => {
    console.log(value)
    this.setState({ changeState: value });
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const orderData = { crm_status: this.state.changeState };

    await service.put('orders/' + this.state.selectedSalesorder_no, orderData)
      .then(res => {
        if (res.data.ok === false) {
          notification["error"]({
            message: res.data.message,
          });
          this.setState({ loading: false })
          return;
        } else {
          this.Pfetch({});
          this.hideModal();
          notification["success"]({
            message: this.props.lang.handle('Estado del Pedido actualizado correctamente'),
          });
        }
      })
  }

  async componentDidMount() {
    this.Pfetch({});
  }

  Pfetch = async (params = {}) => {
    this.setState({ loading: true });
    await service
      .get("orders")
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            loading: false,
            orders: res.data,
          });
        }
      })
      .catch((err) => {
        console.log("Error devuelto: " + err);
      });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.lang.handle('Buscar')}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.lang.handle('Borrar')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  search = (selectedKeys, confirm) => {
    confirm();
    this.setState({
      searchedColumns: selectedKeys,
    });
  };

  reset = (clearFilters) => {
    clearFilters();
    this.setState({
      searchedColumns: [],
    });
  };

  getColumnFilterProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys = this.props.location.state ? [0] : [],
      confirm,
      clearFilters,
    }) => (
      <div className="d-flex flex-column" id="checkbox">
        <Checkbox.Group
          options={this.ORDERS_STATE}
          value={selectedKeys}
          onChange={(values) => setSelectedKeys(values)}
          className="flex-column mx-2 px-2 pb-1 pt-2"
        />
        <Divider className="my-0" />
        <Space className="p-2">
          <Button
            onClick={() => this.reset(clearFilters)}
            size="small"
            className="w-100"
            disabled={selectedKeys.length > 0 ? false : true}
          >
            {this.props.lang.handle('Reiniciar')}
          </Button>
          <Button
            type="primary"
            onClick={() => this.search(selectedKeys, confirm)}
            size="small"
            className="w-100"
          >
            {this.props.lang.handle('Aceptar')}
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value, record) => record.crm_status === value,
    defaultFilteredValue: this.state.searchedColumns
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          botonDisabled: false,
          selectedId: selectedRows[0].salesorderid,
          selectedSalesorder_no: selectedRows[0].salesorder_no,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        name: record.name,
      }),
    };
    const { lang, states } = this.state;
    const columns = [
      {
        title: lang.handle('Número Pedido'),
        dataIndex: "salesorder_no",
        ...this.getColumnSearchProps("salesorder_no"),
      },
      {
        title: lang.handle('Asunto'),
        dataIndex: "subject",
        ...this.getColumnSearchProps("subject"),
      },
      {
        title: lang.handle('Cliente Final'),
        dataIndex: "accountName",
        ...this.getColumnSearchProps("accountName"),
      },
      {
        title: lang.handle('Referencia Presupuesto'),
        dataIndex: "budgetReference",
        ...this.getColumnSearchProps("budgetReference"),
      },
      {
        title: lang.handle('Precio Total'),
        dataIndex: "total",
        sorter: (a, b) => a.total.length - b.total.length,
        render: function (data) {
          if (data === null) {
            return null;
          } else return data + " €";
        },
      },
      {
        title: lang.handle('Asignado a'),
        dataIndex: "assigned",
        ...this.getColumnSearchProps("assigned"),
      },
      {
        title: lang.handle('Fecha de Creación'),
        dataIndex: "dateFormated",
        defaultSortOrder: "descend",
        sorter: (a, b) => new Date(a.creationDate) - new Date(b.creationDate),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: lang.handle('Número Presupuesto'),
        dataIndex: "budgetNumber",
        ...this.getColumnSearchProps("budgetNumber"),
      },
      {
        title: lang.handle('Estado'),
        dataIndex: "crm_status",
        ...this.getColumnFilterProps("crm_status"),
        filterIcon: <FilterFilled />,
        render: (data) => {
          let state;

          states.forEach(element => {
            if (element.value === data) {
              state = element.label;
            }
          });

          return state;
        },
      }
    ];
    const { orders, loading } = this.state;

    orders.map((el) => {
      let date = new Date(el.creationDate);
      return (el.dateFormated = moment(date).format('DD-MM-YYYY HH:mm:ss'));
    });

    return (
      <div>
        <DataTable
          rowSelection={{
            type: "radio",
            ...rowSelection,
          }}
          columns={columns}
          rowKey="salesorder_no"
          dataSource={orders}
          loading={loading}
        />
        <div className="button-panel">
          <Space>
            <Button
              type="primary"
              icon={<SwapOutlined />}
              id="changeState"
              disabled={this.state.botonDisabled}
              onClick={this.showModal}
            >
              {lang.handle('Cambiar Estado')}
            </Button>
            <BotonIrCRM
              disabled={this.state.botonDisabled}
              id={this.state.selectedId}
            />
          </Space>
        </div>

        <Modal
          title={lang.handle('Cambiar estado del pedido') + ": " + this.state.selectedSalesorder_no}
          onCancel={this.handleCancel}
          cancelText={lang.handle('Cerrar')}
          onOk={this.onSubmit}
          okText={lang.handle('Cambiar')}
          icon={<ExclamationCircleOutlined />}
          open={this.state.showModal}>
          {lang.handle('Por favor, seleccione una opción')}
          <Form>
            <Form.Item>
              <Select
                className="form-control"
                name="crm_status"
                onChange={this.handleChange}
                value={this.state.changeState}
                options={this.ORDERS_STATE}
              />
            </Form.Item>
          </Form>
        </Modal>

      </div>
    );
  }

}

export default withLocale(OrdersList);
