import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import { Tabs } from "antd";
import ManufactureBoard from "./components/ManufactureBoard/ManufactureBoard";
import ManufactureKiwi from "./components/ManufactureKiwi/ManufactureKiwi";
import { withLocale } from './../l10n';
function ManufactureNew(props) {
  const navigate = useNavigate();
  const manufacture_create = hasPrivilege('manufacture_create');

  useEffect(() => {
    if (!manufacture_create) {
      navigate("/");
    }
  }, [navigate, manufacture_create])

  const params = useParams();
  const editable = true;
  const newBoard = true;

  const items = [
    { label: props.lang.handle('Nuevo kibox'), key: '1', children: <ManufactureBoard editable={editable} newBoard={newBoard} /> },
    { label: props.lang.handle('Nuevo kiwi'), key: '2', children: <ManufactureKiwi editable={editable} newBoard={newBoard} /> },
  ]

  if (manufacture_create) {
    return (<Tabs
      type="card"
      activeKey={params.tab}
      items={items}
      onChange={key => {
        navigate(`/boards/manufacture/new/${key}`)
      }} />)
  }
}
export default withLocale(ManufactureNew);
