import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../index.css";

export default function PruebaM2M() {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    let url = '';
    const to = params.to;
    switch (to) {
      case 'm2m':
        url = "/simcards/m2m/";
        break;
      default:
        break;
    }

    const id = params.id;

    navigate(url + id);

  }, [navigate, params])
}
