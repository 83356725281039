import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import { Tabs } from "antd";
import SettingsKiconex from "../components/SettingsKiconex";
import SettingsCRM from "../components/SettingsCRM";
import SettingsJDE from "../components/SettingsJDE";
import { withLocale } from './../l10n';

function Settings(props) {
  const navigate = useNavigate();
  const settings = hasPrivilege('settings');

  useEffect(() => {
    if (!settings) {
      navigate("/");
    }
  }, [navigate, settings])

  const params = useParams();

  const items = [
    { label: props.lang.handle('mykiconex'), key: '1', children: <SettingsKiconex /> },
    { label: 'CRM', key: '2', children: <SettingsCRM /> },
    { label: 'JDE', key: '3', children: <SettingsJDE /> },
  ]

  if (settings) {
    return (<Tabs
      type="card"
      activeKey={params.tab}
      items={items}
      onChange={key => {
        navigate(`/system/settings/${key}`)
      }} />)
  }
}

export default withLocale(Settings);
