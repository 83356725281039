import { notification } from 'antd';
import { ACCESS_TOKEN, REFRESH_TOKEN, API_TOKEN, PRIVILEGES_TOKEN } from "../utils/constants";
import service from "../services/Service";

const URL = process.env.REACT_APP_DB_URL_USERS

export function signUpApi(data) {
  return service.post('users/sign-up', data)
    .then((response) => {
      return response.data;
    })
    .then((result) => {
      if (result.user) {
        return { ok: true, message: "Usuario creado correctamente" };
      }
      return { ok: false, message: result.message };
    })
    .catch((err) => {
      if (err.response) {
        // Request made and server responded
        // Ya existe el usuario
        return { ok: false, message: err.response.data.message };
      } else if (err.request) {
        // The request was made but no response was received
        return { ok: false, message: "No hay respuesta del servidor" };
      } else {
        // Something happened in setting up the request that triggered an Error
        return { ok: false, message: "Error" };
      }
    });
}

export function signInApi(data) {
  return service.post('users/sign-in', data)
    .then((response) => {
      return response.data;
    })
    .then((result) => {
      if (result.login) {
        return {
          ok: result.login,
          message: result.message,
          accessToken: result.accessToken,
          refreshToken: result.refreshToken,
          apiToken: result.apiToken,
          privilegesToken: result.privilegesToken
        };
      }
      return { ok: result.login, message: result.message };
    })
    .catch((err) => {
      console.log("Errorr: ", err);
      if (err.response) {
        // Request made and server responded
        // Ya existe el usuario
        return { ok: false, message: err.response.data.message };
      } else if (err.request) {
        // The request was made but no response was received
        return { ok: false, message: "No hay respuesta del servidor" };
      } else {
        // Something happened in setting up the request that triggered an Error
        return { ok: false, message: "Error" };
      }
    });
}

export async function logout() {
  const data = { token: localStorage.getItem(API_TOKEN) };
  return await service.post('users/log-out', data)
    .then((res) => {
      if (res.data) {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        localStorage.removeItem(API_TOKEN);
        localStorage.removeItem(PRIVILEGES_TOKEN);
        return true;
      } else {
        notification["error"]({
          message: "Error al cerrar sesión",
        });
        return false;
      }
    })
}

export function getUsers() {
  const url = URL + "/users";

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      //Authorization: token
    }
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export async function impersonate(uuid) {
  return await service.post("users/" + uuid + "/impersonate")
    .then((res) => {
      if (res.data.ok === false) {
        return { ok: false, message: res.data.message };
      } else {
        localStorage.setItem(ACCESS_TOKEN, res.data.accessToken);
        localStorage.setItem(PRIVILEGES_TOKEN, res.data.privilegesToken);
        localStorage.setItem(API_TOKEN, res.data.apiToken);
        window.location.href = "/";
        return { ok: true };
      }
    })
    .catch((err) => {
      console.log("Error devuelto: " + err);
    });
}
