import React from "react";
import { withLocale } from './../l10n';
 function Error404(props) {
  return (
    <div>
      <h1>{props.lang.handle('Error 404')}...</h1>
    </div>
  );
}
export default withLocale(Error404);
