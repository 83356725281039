import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { hasPrivilege } from "../api/token";
import RolesList from "../components/RolesList";

export default function RolesPage() {
  const navigate = useNavigate();
  const roles_settings = hasPrivilege('roles_settings');

  useEffect(() => {
    if (!roles_settings) {
      navigate("/");
    }
  }, [navigate, roles_settings])

  if (roles_settings) {
    return (
      <div>
        <RolesList />
      </div>
    )
  }
}
