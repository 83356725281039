import React from "react";
import { LocaleContext } from "./index.js";
import { EN_US_KICORE } from './en_US/LC_MESSAGES/EN_US_KICORE.mo.js';
import { FR_FR_KICORE } from './fr_FR/LC_MESSAGES/FR_FR_KICORE.mo.js';
import { DE_DE_KICORE } from './de_DE/LC_MESSAGES/DE_DE_KICORE.mo.js';
import { PT_PT_KICORE } from './pt_PT/LC_MESSAGES/PT_PT_KICORE.mo.js';
import { IT_IT_KICORE } from './it_IT/LC_MESSAGES/IT_IT_KICORE.mo.js';
const translations = {
  en: EN_US_KICORE,
  fr: FR_FR_KICORE,
  de: DE_DE_KICORE,
  pt: PT_PT_KICORE,
  it: IT_IT_KICORE
};

class Locale extends React.Component {
  constructor(props) {
    super(props);

    let langCode = localStorage.getItem("langCode") || "en";
    this.state = {
      current: langCode
    };
    this.handle = this.handle.bind(this);
  }

  handle(s) {
    return translations[this.state.current]?.["catalog"][s] ?? s;
  }

  render() {
    return (
      <LocaleContext.Provider
        value={{
          current: this.state.current,
          handle: this.handle
        }}
      >
        {this.props.children}
      </LocaleContext.Provider>
    );
  }
}

export { Locale };
