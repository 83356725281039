import React, { Component } from 'react';
import '../../../index.css';
import { notification } from 'antd';
import service from "../../../services/Service"
import { withLocale } from "../../../l10n";
import DataTable from '../../../components/DataTable/DataTable';

class PoolTable extends Component {

  state = {
    datos: [],
    loading: false,
    lang: this.props.lang
  };

  getStoredData = async () => {
    var tenant = this.props.tenant;
    this.setState({loading: true});
    await service.get('pools/' + tenant)
      .then(res => {
        if (res.data.ok === false) {
          notification["error"]({
            message: this.props.lang.handle(res.data.message),
          });
          this.setState({
            datos: [],
          });
        } else {
          this.setState({
            datos: res.data,
          });
        }
        this.setState({
          loading: false,
        });
      })
      .catch(err => {
        console.log('Error devuelto: ' + err);
      })
  }

  // Carga la informacion al cargar la pagina
  async componentDidMount() {
    this.getStoredData();
  };

  render() {
    const { lang } = this.state;
    const columns = [
      {
        title: lang.handle('Grupo comercial'),
        dataIndex: ['commercialGroup'],
        render: function (data) {
          let number;

          //Se recortan los nombres para obtener el limite total, si los nombres cambian no funciona
          data = data.replaceAll('_', ' ');
          
          if (data.includes('ZONA')) {
            data = data.slice(0,-5);
            number = data[data.length-1];
            data = data.slice(0,-1)+' '+number;
          } else if (data.includes('eur')) {
            number = data.indexOf(' ', (data.indexOf(' ') + 1));
            data = data.slice(0, -(data.length-number))+' EU';
          } else if (data.includes('Sin Asignar')) {
            data = lang.handle('Sin asignar');
          }

          return <p>{data}</p>;
        }
      },
      {
        title: lang.handle('Tarjetas activas / tarjetas totales'),
        dataIndex: ['activeSim'],
        render: (data) => <p> {data.activeCards}  / {data.totalSim} </p>
      },
      {
        title: lang.handle('Consumo del pool / Pool total'),
        dataIndex: ['consumedData'],
        render: function (data) {
          var tag = "";
          var consumptionTag = "";
          var limit = data.consumedData > data.limitData ? true : false;
          var consumptionData = data.limitData;

          // Modificar tamaño de los datos consumidos
          if (data.consumedData < 1024) {
            tag = "Bytes";
          }
          else if (data.consumedData < 1024*1024) {
            tag = "KB";
            data.consumedData = (data.consumedData / 1024).toFixed(2);
          }
          else if (data.consumedData < 1024*1024*1024) {
            tag = "MB";
            data.consumedData = ((data.consumedData / 1024) / 1024).toFixed(2);
          }
          else {
            tag = "GB";
            data.consumedData = (((data.consumedData / 1024) / 1024) / 1024).toFixed(2);
          }

          // Modificar tamaño de los datos consumidos
          if (consumptionData < 1024) {
            consumptionTag = "Bytes";
          }
          else if (consumptionData < 1024*1024) {
            consumptionTag = "KB";
            consumptionData = (consumptionData / 1024).toFixed(2);
          }
          else if (consumptionData < 1024*1024*1024) {
            consumptionTag = "MB";
            consumptionData = ((consumptionData / 1024) / 1024).toFixed(2);
          }
          else {
            consumptionTag = "GB";
            consumptionData = (((consumptionData / 1024) / 1024) / 1024).toFixed(2);
          }

          return limit ? <p> <span className='text-danger'>{data.consumedData} {tag}</span> / {consumptionData} {consumptionTag}</p> : <p> {data.consumedData} {tag} / {consumptionData} {consumptionTag}</p>;
        }
      },
    ]
    const { datos, loading } = this.state;

    return (
      <div>
        <DataTable
          columns={columns}
          dataSource={datos}
          pagination={false}
          rowKey="pool_id"
          loading={loading}
        />
      </div>
    );
  }
}

export default withLocale(PoolTable);
