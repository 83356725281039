import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTokenInfo, hasPrivilege } from "../api/token";
import UserCard from "../components/UserCard";

export default function EditUser() {
  const navigate = useNavigate();
  const params = useParams();
  const users_edit = hasPrivilege('users_edit') || params.uuid === getTokenInfo().uuid;

  useEffect(() => {
    if (!users_edit) {
      navigate("/");
    }
  }, [navigate, users_edit])

  if (users_edit) {
    const editable = true;
    const ident = params.uuid;

    return (
      <div>
        <UserCard editable={editable} ident={ident} />
      </div>
    )
  }
}
